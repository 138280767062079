import InfoErrorIcon from 'images/info-error.svg'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import RootState from 'store/state'
import { changePassword } from 'store/user/user.actions'

import { Button, Form, Input } from '@missionlabs/smartagent-app-components'
import { H } from 'react-accessible-headings'

import GreenTick from '../GreenTick.svg'
import Tick from '../Tick-fill.svg'
import { checkPasswordValidation } from '../utils'

interface Props extends ReturnType<typeof mapStateToProps> {
    username: string
    changePassword: typeof changePassword
}

const NewPasswordRequired: React.FC<Props> = ({
    username,
    authError,
    authenticating,
    changePassword,
}) => {
    const [pass, setPass] = useState('')
    const [error, setError] = useState('')
    const [isValidPassword, setIsValidPassword] = useState(false)

    const [longPassword, setLongPassword] = useState(false)
    const [lowercaseLetter, setLowercaseLetter] = useState(false)
    const [uppercaseLetter, setUppercaseLetter] = useState(false)
    const [digit, setDigit] = useState(false)

    useEffect(() => {
        const { longPassword, lowercase, uppercase, digit } = checkPasswordValidation(pass)

        setLongPassword(longPassword)
        setLowercaseLetter(lowercase)
        setUppercaseLetter(uppercase)
        setDigit(digit)

        if (longPassword && lowercase && uppercase && digit) {
            setIsValidPassword(true)
        } else {
            setIsValidPassword(false)
        }
    }, [pass])

    const onSubmit = () => {
        if (!isValidPassword) return setError('Password is not valid')
        changePassword({ username, newPassword: pass })
    }

    const unchecked = <img src={Tick} alt="Failed" title="Failed" width="14px" height="14px" />
    const checked = (
        <img src={GreenTick} alt="Approved" title="Approved" width="14px" height="14px" />
    )

    return (
        <Form onSubmit={onSubmit} className="sa-login-form">
            <div className="row between middle">
                <H className="title">Set a new password</H>
            </div>

            <div className="password-validation">
                <ul>
                    <li>{longPassword ? checked : unchecked} Must be at least 8 characters long</li>
                    <li>{lowercaseLetter ? checked : unchecked} Must include 1 lowercase letter</li>
                    <li>{uppercaseLetter ? checked : unchecked} Must include 1 uppercase letter</li>
                    <li>{digit ? checked : unchecked} Must include 1 digit</li>
                    {/* <li>{specialCharacter ? checked : unchecked} Must include 1 special character </li> */}
                </ul>
            </div>

            <Input
                className="md-mar-bottom"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                onChange={(pass: any) => {
                    setPass(pass)
                    setError('')
                }}
                value={pass}
                required
                type="password"
                label="New Password"
                autoComplete='new-password'
            />
            {(error || authError) && (
                <div className="sa-login-error md-mar-bottom" aria-live="polite">
                    <img alt="Error" src={InfoErrorIcon} /> {error || authError}
                </div>
            )}
            <Button round large styling="primary" disabled={!isValidPassword}>
                {authenticating ? <ClipLoader color="#fff" size={20} /> : 'Change Password'}
            </Button>
        </Form>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        ...state.auth,
    }
}

export default connect(mapStateToProps, { changePassword })(NewPasswordRequired)
