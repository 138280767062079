import { toggleIframe } from 'store/app/app.reducer'
import { callEnded, incomingCall } from 'store/call/call.reducer'
import { logout } from 'store/user/user.reducer'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import GlobalState, { SetChangingStateAction, ShowNotificationAction } from './global.state'

const initialState: GlobalState = {
    numberDialling: '',
    changingState: '',
    error: null,
    success: null,
    softphoneError: null,
    showIframe: false,
    online: true,
    redirect: null,
    showNotification: null,
    saIsAlreadyOpen: true,
}

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        addError(state, action: PayloadAction<string>) {
            return { ...state, error: action.payload }
        },
        addSuccess(state, action: PayloadAction<string>) {
            return { ...state, success: action.payload }
        },
        internetConnection(state, action: PayloadAction<boolean>) {
            return { ...state, online: action.payload }
        },
        addSoftphoneError(state, action: PayloadAction<connect.SoftphoneError['errorType']>) {
            return { ...state, softphoneError: action.payload }
        },
        clearSoftphoneError(state) {
            return { ...state, softphoneError: null }
        },
        clearError(state) {
            return { ...state, error: null }
        },
        clearSuccess(state) {
            return { ...state, success: null }
        },
        showNotification(state, action: PayloadAction<ShowNotificationAction>) {
            return { ...state, showNotification: { ...action.payload } }
        },
        doRedirect(state, action: PayloadAction<GlobalState['redirect']>) {
            return { ...state, redirect: action.payload }
        },
        clearRedirect(state) {
            return { ...state, redirect: null }
        },
        setChangingState(state, action: PayloadAction<SetChangingStateAction>) {
            return { ...state, changingState: action.payload.stateName }
        },
        numberDialling(state, action: PayloadAction<string>) {
            return { ...state, numberDialling: action.payload }
        },
        setSaIsAlreadyOpen(state, action: PayloadAction<boolean>) {
            return { ...state, saIsAlreadyOpen: action.payload }
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(toggleIframe, (state) => {
            return { ...state, showIframe: !state.showIframe }
        }),
            addCase(incomingCall, (state) => {
                return { ...state, softphoneError: null }
            }),
            addCase(callEnded, (state) => {
                return { ...state, numberDialling: '' }
            }),
            addCase(logout, (state) => {
                return { ...initialState, saIsAlreadyOpen: state.saIsAlreadyOpen }
            })
    },
})

export const {
    addError,
    addSuccess,
    internetConnection,
    addSoftphoneError,
    clearSoftphoneError,
    clearError,
    clearSuccess,
    showNotification,
    doRedirect,
    clearRedirect,
    setChangingState,
    numberDialling,
    setSaIsAlreadyOpen,
} = globalSlice.actions

export type GlobalAction = ReturnType<
    (typeof globalSlice.actions)[keyof typeof globalSlice.actions]
>

export default globalSlice.reducer
