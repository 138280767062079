import * as callActions from '../../call/call.actions'

const setShortcuts = (store: any, OS: string) => {
    document.addEventListener('keydown', (evt) => {
        // Document container elements
        const diallerKeypad: Element | null = document.querySelector('.dialler-keypad')
        const diallerControls: Element | null = document.querySelector('.dialler-controls')

        switch (
            evt.shiftKey &&
            (OS === 'Mac' ? evt.ctrlKey : evt.altKey) &&
            evt.key // APP ACTIONS
        ) {
            case 'D': {
                // Makes a call if there is a number on the dial number input
                const numberDialling = store.getState().global.numberDialling
                return numberDialling ? store.dispatch(callActions.makeCall(numberDialling)) : null
            }

            case 'R': {
                // Rejects a call if there is an incoming call
                return store.getState().call?.incoming
                    ? store.dispatch(callActions.rejectCall())
                    : null
            }

            case 'E': {
                // End a call if there is on active
                return store.getState().call ? store.dispatch(callActions.endCall()) : null
            }

            case 'Q': {
                //Opens/Closes Quick connect popup
                const quickConnect: HTMLButtonElement | null | undefined =
                    diallerKeypad?.querySelector('.quick-connect')
                return quickConnect?.click()
            }

            case 'K': {
                //Opens/Closes Keypad popup
                const keypad: HTMLButtonElement | null | undefined =
                    diallerKeypad?.querySelector('.keypad')
                const inCallKeypad: HTMLButtonElement | null | undefined =
                    diallerControls?.querySelector('.call-keypad')
                return keypad ? keypad.click() : inCallKeypad ? inCallKeypad.click() : null
            }

            case 'N': {
                //Set focus on dial number input
                const numberDialler: HTMLInputElement | undefined | null =
                    diallerKeypad?.querySelector('.remote-number')
                return numberDialler?.focus()
            }

            case 'S': {
                //Opens/Closes user status popup
                const userDetails: Element | null = document?.querySelector('.user-details')
                const saStatusButton: HTMLButtonElement | null | undefined =
                    userDetails?.querySelector('.sa-status-button')
                return saStatusButton?.click()
            }

            case 'H': {
                //Set call on hold/resume call
                const activeConnection = store
                    .getState()
                    .call.connections.find((c: any) => c.activeConnection)
                const activeConnectionID = activeConnection?.id!
                return store.getState().call
                    ? activeConnection?.hold
                        ? store.dispatch(callActions.resume(activeConnectionID))
                        : store.dispatch(callActions.hold(activeConnectionID))
                    : null
            }

            case 'W': {
                //Toggles controls to add another connection
                const toggleDial: HTMLButtonElement | null | undefined =
                    diallerControls?.querySelector('.transfer')
                return toggleDial?.click()
            }

            case 'G': {
                //Toggles between active connections
                return store.getState().call?.connections.length > 1
                    ? store.dispatch(callActions.toggleConnections())
                    : null
            }

            case 'C': {
                //Create a conference call with both connections
                return store.getState().call?.connections.length > 1
                    ? store.dispatch(callActions.conferenceConnections())
                    : null
            }

            case 'L': {
                //Leave conference call
                return store.getState().call?.connections.length > 1
                    ? store.dispatch(callActions.leaveCall())
                    : null
            }
        }
    })
}

export default setShortcuts
