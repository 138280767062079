import { jsx } from "react/jsx-runtime";
import { useState } from "react";
import classNames from "classnames";
const QuickRepliesMultiLine = ({
  ariaId,
  quickReplies,
  sendMessage,
  showQuickRepliesOnMount = true,
  translations
}) => {
  const [disabled, setDisabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleQuickReplyClick = (qr, index) => {
    if (disabled)
      return;
    setDisabled(true);
    setSelectedIndex(index);
    sendMessage(qr.payload, qr.title);
  };
  if (!(quickReplies == null ? void 0 : quickReplies.length) || !showQuickRepliesOnMount)
    return null;
  const ariaLabel = translations.getSelectXOptionsFromY(1, quickReplies == null ? void 0 : quickReplies.length);
  return /* @__PURE__ */ jsx("div", { id: ariaId, "aria-label": ariaLabel, className: "sa-qr-wrapper-multiline", children: /* @__PURE__ */ jsx("ul", { children: quickReplies.map((qr, index) => /* @__PURE__ */ jsx("li", { children: /* @__PURE__ */ jsx(
    "button",
    {
      className: classNames("sa-qr-button-multiline", {
        "sa-qr-button-selected-multiline": selectedIndex === index
      }),
      onClick: () => handleQuickReplyClick(qr, index),
      disabled,
      children: /* @__PURE__ */ jsx("span", { children: qr.title })
    }
  ) }, qr.title)) }) });
};
export {
  QuickRepliesMultiLine as default
};
