import { Button, TriggeredPopup } from '@missionlabs/smartagent-app-components'
import CheckboxList, { Option } from 'components/CheckboxList'
import FilterIcon from 'images/icon-filter.svg'
import React, { useEffect, useRef, useState } from 'react'
import './metric-filter-list.scss'

interface ComponentProps {
    options: Option[]
    text: React.ReactNode
    onSave: (options: Option[] | undefined) => void
}

type Props = ComponentProps

const MetricFilterList: React.FC<Props> = ({ options, text, onSave }) => {
    const [cachedOptions, setCachedOptions] = useState<Option[]>(
        options.sort((a, b) => (a.label > b.label ? 1 : -1)),
    )
    const allSelected = !cachedOptions.find((f) => !f.checked)
    const oldAllselected = !options.find((f) => !f.checked)
    const oldSelected = options.filter((f) => f.checked).length
    const popup = useRef<TriggeredPopup>(null)

    useEffect(() => {
        const cachedOptionsLabels = cachedOptions.map((co) => co.label)
        const optionsLabels = options.map((o) => o.label)
        const optionsChanged =
            optionsLabels.length !== cachedOptionsLabels.length ||
            optionsLabels.filter((ol) => !cachedOptionsLabels.includes(ol)).length > 0

        if (optionsChanged) {
            setCachedOptions(options)
        }
    }, [options])

    const onChange = (option: Option, checked: boolean) => {
        if (option.value === 'ALL') {
            return setCachedOptions(
                cachedOptions.map((co) => ({
                    ...co,
                    checked: checked,
                })),
            )
        }
        setCachedOptions(
            cachedOptions.map((co) => ({
                ...co,
                checked: co.value === option.value ? checked : co.checked,
            })),
        )
    }

    const onClick = () => {
        popup.current!.closePopup()
        onSave(allSelected ? undefined : cachedOptions.filter((co) => co.checked))
    }

    return (
        <TriggeredPopup
            ref={popup}
            className="metric-filter-checkbox-list"
            fromRight
            closeOnClickOutside
            autoPositioning
            autoPositioningOffset={200}
            trigger={
                <Button
                    title="Queue filter"
                    aria-label="Queue, currently selected queue"
                    className="sa-metric-filter-button"
                    round
                    elastic
                    icon={<img alt="Filter" title="Filter" aria-hidden src={FilterIcon} />}
                >
                    <span className="xsm-mar-right">
                        {oldAllselected ? text : `${oldSelected} selected`}
                    </span>
                </Button>
            }
        >
            <div>
                <CheckboxList
                    onChange={onChange}
                    options={[
                        {
                            label: 'All',
                            checked: allSelected,
                            value: 'ALL',
                        },
                        ...cachedOptions.map((o) => ({
                            ...o,
                            checked: o.checked,
                        })),
                    ]}
                />
                <div className="sa-metric-save">
                    <Button
                        className="stretch row full-width alt-font"
                        onClick={onClick}
                        data-testid="sa-metric-save-button"
                    >
                        Save
                    </Button>
                </div>
            </div>
        </TriggeredPopup>
    )
}

export default MetricFilterList
