import { Box, Table } from '@missionlabs/smartagent-app-components'
import { NoDataMessage, ToolBar } from 'components'
import useSettings from 'hooks/redux/useSettings'
import useUser from 'hooks/redux/useUser'
import { AppFeatures, useGetFeatureConfig } from 'hooks/useHasFeature'
import { useMemo } from 'react'
import { IQueue } from 'store/settings/settings.state'
import { IAgentQueueStatsV2 } from 'store/user/user.state'
import { secondsToTime } from 'utils'
import useContactPanelContext from 'views/ContactPanel/useContactPanelContext'

const getQueueName = (queues: IQueue[], queueId: string) => {
    const queue = queues.find((queue) => {
        return queue.Id === queueId
    })

    return queue?.Name ?? 'Personal Queue'
}

const getAgentTableStatsV2 = (
    agentContactStatsByQueueV2: IAgentQueueStatsV2[],
    enabledQueues: IQueue[],
) =>
    Object.values(
        agentContactStatsByQueueV2.reduce(
            (stats, agentContactStats, i) => {
                const queueName = getQueueName(enabledQueues, agentContactStats.queueID)

                const existedStat = stats[queueName]

                if (existedStat) {
                    stats[queueName] = {
                        ...existedStat,
                        handled: existedStat.handled + agentContactStats.handled,
                        contactTime: existedStat.contactTime + agentContactStats.contactTime,
                        avgHandledTime:
                            existedStat.avgHandledTime + agentContactStats.avgHandledTime,
                        avgHoldTime: existedStat.avgHoldTime + agentContactStats.avgHoldTime,
                        avgAcwTime: existedStat.avgAcwTime + agentContactStats.avgAcwTime,
                    }
                } else {
                    stats[queueName] = {
                        ID: i.toString(),
                        queue: queueName,
                        handled: agentContactStats.handled,
                        contactTime: agentContactStats.contactTime,
                        avgHandledTime: agentContactStats.avgHandledTime,
                        avgHoldTime: agentContactStats.avgHoldTime,
                        avgAcwTime: agentContactStats.avgAcwTime,
                    }
                }

                return stats
            },
            {} as {
                [key: string]: {
                    ID: string
                    queue: string
                    handled: number
                    contactTime: number
                    avgHandledTime: number
                    avgHoldTime: number
                    avgAcwTime: number
                }
            },
        ),
    ).map((stat) => ({
        ...stat,
        contactTime: secondsToTime(stat.contactTime),
        avgHandledTime: secondsToTime(stat.avgHandledTime),
        avgHoldTime: secondsToTime(stat.avgHoldTime),
        avgAcwTime: secondsToTime(stat.avgAcwTime),
    }))

const AgentStats = () => {
    const { agentContactStatsByQueue, agentContactStatsByQueueV2 } = useUser() ?? {}
    const { enabledQueues } = useSettings()

    const { agentsOpen, setAgentsOpen } = useContactPanelContext()

    const v1Config = useGetFeatureConfig()(AppFeatures.QUEUE_STATS)
    const v2Config = useGetFeatureConfig()(AppFeatures.REALTIME_DATA_AGENTS_REDESIGNED)

    const areMetricsHidden = useMemo(() => {
        return v1Config?.hideHistoricMetrics || v2Config.hideHistoricMetrics
    }, [v1Config, v2Config])

    const agentTableStats = useMemo(() => {
        if (agentContactStatsByQueue) {
            return agentContactStatsByQueue.map((queueStats, i) => ({
                ID: i.toString(),
                queue: queueStats.queueName,
                handled: queueStats.handled,
                contactTime: secondsToTime(queueStats.contactTime),
                avgHandledTime: secondsToTime(queueStats.avgHandledTime),
                avgHoldTime: secondsToTime(queueStats.avgHoldTime),
                avgAcwTime: secondsToTime(queueStats.avgAcwTime),
            }))
        }

        if (agentContactStatsByQueueV2 && enabledQueues) {
            return getAgentTableStatsV2(agentContactStatsByQueueV2, enabledQueues)
        }
    }, [agentContactStatsByQueue, agentContactStatsByQueueV2, enabledQueues])

    const contactColumns = [
        {
            value: 'queue',
            name: 'Queue',
            sort: true,
        },
        {
            value: 'handled',
            name: 'Handled',
            sort: true,
            tooltip: 'Count of contacts handled by an agent',
        },
        {
            value: 'contactTime',
            name: 'contact time',
            sort: true,
            tooltip: '',
        },
        {
            value: 'avgHandledTime',
            name: 'avg handled time',
            sort: true,
            tooltip: 'Average handled time',
        },
        {
            value: 'avgHoldTime',
            name: 'avg hold time',
            sort: true,
            tooltip: 'Average hold time',
        },
        {
            value: 'avgAcwTime',
            name: 'avg acw time',
            sort: true,
            tooltip: 'Average time agent spent in after contact work',
        },
    ]

    return (
        <>
            {!areMetricsHidden && (
                <Box
                    onToggle={setAgentsOpen}
                    controlledHidden={agentsOpen}
                    collapse
                    alt
                    boxLabel="Contacts"
                    header={<ToolBar text="My Contacts" items={[]} />}
                >
                    <Table
                        sort="queue"
                        caption="Agents"
                        cols={contactColumns}
                        data={agentTableStats}
                        noData={<NoDataMessage />}
                        selectable
                    />
                </Box>
            )}
        </>
    )
}

export default AgentStats
