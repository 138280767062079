import { jsx, jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { useId } from "react";
import AcceptMessage from "./AcceptMessage.mjs";
import Accordion from "./Accordion.mjs";
import BigButtons from "./BigButtons.mjs";
import CardChatMessage from "./CardChatMessage.mjs";
import { i as isDate, f as formatDateWithDash, D as DatePickerMessage } from "./index-HE6_Ex2n.mjs";
import { formatDateTime } from "@missionlabs/smartagent-app-components";
import { M as MessageUserType } from "./chat-message.types-V2YsTtfG.mjs";
import PlainTextChatMessage from "./PlainTextChatMessage.mjs";
import FileMessage from "./FileMessage.mjs";
import HoldingStatusMessage from "./HoldingStatusMessage.mjs";
import IframeMessage from "./IframeMessage.mjs";
import QuickReplies from "./QuickReplies.mjs";
import QuickRepliesMultiLine from "./QuickRepliesMultiLine.mjs";
import PaymentSliderCard from "./RangeSliderCard.mjs";
import StarRating from "./StarRating.mjs";
import Translation from "./Translation.mjs";
import PlainTextChatMessage$1 from "./TypingIndicator.mjs";
const DatePickerResponse = ({ selectedDate, translations, senderDisplayName }) => {
  if (!selectedDate)
    return null;
  const createdDate = new Date(selectedDate);
  const formattedDate = isDate(createdDate) && formatDateWithDash(formatDateTime(createdDate));
  return /* @__PURE__ */ jsx(
    PlainTextChatMessage,
    {
      clientType: MessageUserType.AGENT,
      text: formattedDate,
      type: "TEXT",
      time: (/* @__PURE__ */ new Date()).toISOString(),
      senderType: MessageUserType.CUSTOMER,
      senderDisplayName,
      translations
    }
  );
};
const UnreadableMessageTypes = ["INVISIBLE_MESSAGE"];
const ChatMessageSender = ({ id, name, type, translations }) => {
  if (!name || UnreadableMessageTypes.includes(type))
    return null;
  return /* @__PURE__ */ jsxs("span", { id, className: "screen-reader-only", children: [
    name,
    " ",
    translations.says,
    ":"
  ] });
};
const defaultTranslations = {
  typingAriaLabel: "typing",
  newMessageNotificationText: "There's a new text from agent",
  starRatingAriaLabel: "star rating",
  noRatingAriaLabel: "No rating",
  starAriaLabel: "star",
  starsAriaLabel: "stars",
  quickRepliesAriaLabel: "Quick replies",
  statusLabels: {
    read: "Read",
    delivered: "Delivered",
    sent: "Sent"
  },
  readAtAriaLabel: "Read at",
  hideText: "Hide",
  seeText: "See",
  messageText: "Message",
  downloadLabel: "Download",
  receivedAtText: "received at",
  sentAtText: "sent at",
  says: "says",
  submit: "Submit",
  cancel: "Cancel",
  getSelectXOptionsFromY: (x, y) => `Select ${x} from ${y}`
};
const ChatMessage = ({
  className,
  senderType,
  time,
  clientType,
  body,
  children,
  companyLogoUrl,
  userIconUrl,
  sendMessage,
  updateContact,
  senderDisplayName,
  isMarkdownEnabled,
  isHTMLEnabled,
  messageHeader,
  onMessageSizeChange,
  receiptsActive,
  deliveredTimestamp,
  readTimestamp,
  customMessageStyle,
  ignoreTransformToLinkTexts,
  translations = defaultTranslations,
  errorText
}) => {
  const senderId = useId();
  const contentId = useId();
  const contentId2 = useId();
  const onRight = () => {
    if (!(body == null ? void 0 : body.type))
      return false;
    const positionableTypes = [
      "TEXT",
      "TEXT_QUICKREPLIES",
      "FILE_LINK",
      "QUICK_REPLIES",
      "DATE_PICKER_RESPONSE"
    ];
    if (!positionableTypes.includes(body.type))
      return false;
    if (clientType === MessageUserType.AGENT && [MessageUserType.AGENT, MessageUserType.SYSTEM].includes(senderType))
      return true;
    if (clientType === MessageUserType.CUSTOMER && senderType === MessageUserType.CUSTOMER)
      return true;
    return false;
  };
  return /* @__PURE__ */ jsxs(
    "div",
    {
      className: classNames({
        [className]: !!className,
        "sa-chat-message-wrapper": true,
        right: onRight()
      }),
      tabIndex: 0,
      "aria-labelledby": `${senderId} ${contentId} ${contentId2}`,
      children: [
        /* @__PURE__ */ jsx(
          ChatMessageSender,
          {
            id: senderId,
            name: senderDisplayName,
            type: body == null ? void 0 : body.type,
            translations: {
              says: translations.says
            }
          }
        ),
        (() => {
          if (!body)
            return children || null;
          switch (body.type) {
            case "TYPING":
              return /* @__PURE__ */ jsx(
                PlainTextChatMessage$1,
                {
                  ariaId: contentId,
                  senderType,
                  companyLogoUrl,
                  translations: {
                    typingAriaLabel: translations.typingAriaLabel
                  }
                }
              );
            case "INVISIBLE_MESSAGE":
              return null;
            case "TEXT":
            case "TEXT_QUICKREPLIES":
              return /* @__PURE__ */ jsx(
                PlainTextChatMessage,
                {
                  ariaId: contentId,
                  companyLogoUrl,
                  userIconUrl,
                  senderType,
                  clientType,
                  time,
                  senderDisplayName,
                  isMarkdownEnabled,
                  isHTMLEnabled,
                  sendMessage: sendMessage ?? (() => {
                  }),
                  messageHeader,
                  onMessageSizeChange,
                  receiptsActive,
                  deliveredTimestamp,
                  readTimestamp,
                  customMessageStyle,
                  ignoreTransformToLinkTexts,
                  translations: {
                    newMessageNotificationText: translations.newMessageNotificationText,
                    quickRepliesAriaLabel: translations.quickRepliesAriaLabel,
                    statusLabels: translations.statusLabels,
                    hideText: translations.hideText,
                    messageText: translations.messageText,
                    seeText: translations.seeText,
                    receivedAtText: translations.receivedAtText,
                    sentAtText: translations.sentAtText,
                    getSelectXOptionsFromY: translations.getSelectXOptionsFromY
                  },
                  ariaContentId: contentId2,
                  ...body
                }
              );
            case "CARD":
              return /* @__PURE__ */ jsx(
                CardChatMessage,
                {
                  ariaId: contentId,
                  sendMessage: sendMessage ?? (() => {
                  }),
                  cardsMsg: body,
                  isMarkdownEnabled
                }
              );
            case "FILE_LINK":
              return /* @__PURE__ */ jsx(
                FileMessage,
                {
                  ariaId: contentId,
                  ...body,
                  time,
                  receiptsActive,
                  senderType,
                  clientType,
                  deliveredTimestamp,
                  readTimestamp,
                  translations: {
                    readAtAriaLabel: translations.readAtAriaLabel,
                    statusLabels: translations.statusLabels,
                    downloadLabel: translations.downloadLabel
                  }
                }
              );
            case "RANGE_SLIDER":
              return /* @__PURE__ */ jsx(
                PaymentSliderCard,
                {
                  ariaId: contentId,
                  ...body,
                  startDisabled: clientType === MessageUserType.AGENT,
                  sendMessage: sendMessage ?? (() => {
                  })
                }
              );
            case "BIG_BUTTON":
              return /* @__PURE__ */ jsx(
                BigButtons,
                {
                  ariaId: contentId,
                  ...body,
                  sendMessage: sendMessage ?? (() => {
                  })
                }
              );
            case "IFRAME":
              return /* @__PURE__ */ jsx(
                IframeMessage,
                {
                  ariaId: contentId,
                  ...body,
                  sendMessage: sendMessage ?? (() => {
                  })
                }
              );
            case "DATE_PICKER":
              return /* @__PURE__ */ jsx(
                DatePickerMessage,
                {
                  ariaId: contentId,
                  ...body,
                  sendMessage: sendMessage ?? (() => {
                  })
                }
              );
            case "DATE_PICKER_RESPONSE":
              return /* @__PURE__ */ jsx(
                DatePickerResponse,
                {
                  senderDisplayName,
                  translations,
                  ...body
                }
              );
            case "ACCEPT_MESSAGE":
              return /* @__PURE__ */ jsx(
                AcceptMessage,
                {
                  ariaId: contentId,
                  sendMessage: sendMessage ?? (() => {
                  }),
                  acceptMsg: body
                }
              );
            case "HOLDING_STATUS":
              return /* @__PURE__ */ jsx(
                HoldingStatusMessage,
                {
                  ariaId: contentId,
                  ...body,
                  companyLogoUrl,
                  messageTime: time
                }
              );
            case "ACCORDION":
              return /* @__PURE__ */ jsx(
                Accordion,
                {
                  ariaId: contentId,
                  ...body,
                  updateContact,
                  sendMessage: sendMessage ?? (() => {
                  })
                }
              );
            case "TRANSLATION":
              return /* @__PURE__ */ jsx(Translation, { ariaId: contentId, clientType, ...body });
            case "STAR_RATING":
              return /* @__PURE__ */ jsx(
                StarRating,
                {
                  ariaId: contentId,
                  sendMessage: sendMessage ?? (() => {
                  }),
                  updateContact,
                  companyLogoUrl,
                  translations: {
                    newMessageNotificationText: translations.newMessageNotificationText,
                    starRatingAriaLabel: translations.starRatingAriaLabel,
                    starAriaLabel: translations.starAriaLabel,
                    starsAriaLabel: translations.starsAriaLabel,
                    noRatingAriaLabel: translations.noRatingAriaLabel,
                    quickRepliesAriaLabel: translations.quickRepliesAriaLabel,
                    statusLabels: translations.statusLabels,
                    hideText: translations.hideText,
                    messageText: translations.messageText,
                    seeText: translations.seeText,
                    receivedAtText: translations.receivedAtText,
                    sentAtText: translations.sentAtText,
                    getSelectXOptionsFromY: translations.getSelectXOptionsFromY,
                    cancel: translations.cancel,
                    submit: translations.submit
                  },
                  ...body
                }
              );
            case "QUICK_REPLIES":
              return /* @__PURE__ */ jsx(
                QuickReplies,
                {
                  ariaId: contentId,
                  sendMessage: sendMessage ?? (() => {
                  }),
                  translations: {
                    quickRepliesAriaLabel: translations.quickRepliesAriaLabel
                  },
                  ...body
                }
              );
            case "QUICK_REPLIES_MULTILINE":
              return /* @__PURE__ */ jsx(
                QuickRepliesMultiLine,
                {
                  ariaId: contentId,
                  sendMessage: sendMessage ?? (() => {
                  }),
                  translations: {
                    getSelectXOptionsFromY: translations.getSelectXOptionsFromY
                  },
                  ...body
                }
              );
            default:
              return null;
          }
        })(),
        errorText && /* @__PURE__ */ jsx("p", { "aria-label": "chat error message", className: "sa-chat-message-error-text", children: errorText })
      ]
    }
  );
};
export {
  ChatMessage as default
};
