import { TimeElapsed } from '@missionlabs/smartagent-app-components'
import classnames from 'classnames'
import { ReactComponent as ClockIcon } from 'images/icon-clock-small.svg'
import { ReactComponent as LastSeenIcon } from 'images/icon-lastseen-small.svg'
import styles from './ChatInfo.module.scss'
import classNames from 'classnames'

interface Props {
    isMissed: boolean
    chatTimeStarted: number
    timeFromLastMessage: Date
    isSelected: boolean
    acw: boolean
    channel?: string
    subChannel?: string
}

const ChatInfo = ({
    isMissed,
    chatTimeStarted,
    timeFromLastMessage,
    isSelected,
    acw,
    channel,
    subChannel,
}: Props) => {
    const renderChatType = () => {
        if (!channel) return

        let renderChannel,
            renderSubChannel = ''
        if (channel === 'INSTAGRAM') {
            renderChannel = 'Instagram '
        } else if (channel === 'FACEBOOK') {
            renderChannel = 'FB '
        } else if (channel === 'TWITTER') {
            renderChannel = 'Twitter '
        } else {
            renderChannel = channel
        }

        if (subChannel) {
            switch (subChannel) {
                case 'DM':
                case 'MESSENGER':
                case 'IG-DM':
                    renderSubChannel = 'DM'
                    break
                case 'TWEET':
                    renderSubChannel = 'Tweet'
                    break
                case 'POST':
                    renderSubChannel = 'Comment'
                    break
                case 'IG-COMMENT':
                    if (channel === 'INSTAGRAM') {
                        renderChannel = 'Insta-'
                        renderSubChannel = 'Comm'
                    }
                    break
                default:
                    break
            }
        }

        const className = classNames(styles.chatType, {
            [styles['chatType-selected']]: isSelected || acw
        })

        return (
            <p className={className}>
                {renderChannel}
                {renderSubChannel}
            </p>
        )
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.info}>
                <div className={styles.infoInner}>
                    <p className={`${styles.indicator}`}>
                        {isSelected || acw ? (
                            <ClockIcon className={styles['indicator--selected']} />
                        ) : (
                            <ClockIcon />
                        )}

                        <TimeElapsed format="mm:ss" minutes date={new Date(chatTimeStarted)} />
                    </p>

                    {!isMissed && (
                        <p className={classnames(styles.indicator)}>
                            {isSelected || acw ? (
                                <LastSeenIcon className={styles['indicator--selected']} />
                            ) : (
                                <LastSeenIcon />
                            )}

                            <TimeElapsed format="mm:ss" minutes date={timeFromLastMessage} />
                        </p>
                    )}
                </div>
                {renderChatType()}
            </div>
        </div>
    )
}

export default ChatInfo
