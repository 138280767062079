import useHasFeature, { AppFeatures, AppSubFeatures } from 'hooks/useHasFeature'
import useHasRole from 'hooks/useHasRole'
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { clearContact } from 'store/contact/contact.actions'
import { conditionalPluralise } from 'utils'
import './nav-element.scss'
import { Button } from '@missionlabs/smartagent-app-components'

export interface LinkProps {
    title: string
    path: string
    name: string
    external?: boolean
    subfeatures?: string[]
    roles?: string[]
    clearContactOnClick?: boolean
    notificationCount?: number
}

interface Props extends RouteComponentProps {
    title?: string
    links?: LinkProps[]
    icon?: React.ReactNode
    selected?: boolean
    path?: string
    onClick?: () => void
    formatTitle?: (title: string, notificationCount: number) => string
    onNavigate?: () => void
}

const NavElement: React.FC<Props> = (props) => {
    const [showLinks, setShowLinks] = useState<boolean>(false)
    const [selectedMainLink, setSelectedMainLink] = useState<HTMLElement | null>(null)

    // NavElement refs
    const submenu = useRef<HTMLUListElement>(null)
    const titleLink = useRef<HTMLAnchorElement>(null)
    const mainLink = useRef<HTMLAnchorElement | HTMLButtonElement>(null)
    const childLink = useRef<HTMLAnchorElement>(null)

    // Auto focus on the first link of the submenus, or link title if its focusable
    useEffect(() => {
        if (showLinks && selectedMainLink) {
            const firstChild = submenu.current?.firstChild?.firstChild as HTMLElement
            if (submenu.current) {
                firstChild.focus()
            } else {
                titleLink.current?.focus()
            }
        }
        if (!showLinks) setSelectedMainLink(null)
    }, [showLinks, selectedMainLink])

    const dispatch = useDispatch()

    const hideNavLinksListener = (e: globalThis.KeyboardEvent) => {
        if (e.key === 'Escape') hideNavLinks()
    }

    const showNavLinks = () => {
        document.addEventListener('keyup', hideNavLinksListener)
        setShowLinks(true)
    }

    const hideNavLinks = () => {
        document.removeEventListener('keyup', hideNavLinksListener)
        setShowLinks(false)
    }

    const handleTab = (e: KeyboardEvent) => {
        const firstChild = submenu.current?.firstChild?.firstChild as HTMLAnchorElement
        const lastChild = submenu.current?.lastChild?.firstChild as HTMLAnchorElement
        // Submenus with focusable titles won't have links, stop tabbing when focused on title links
        if (e.target === titleLink.current && e.key === 'Tab') {
            e.preventDefault()
            return
        }

        // If focused on the last link on tab focus on the first link
        if (e.target === lastChild && !e.shiftKey && e.key === 'Tab') {
            e.preventDefault()
            firstChild.focus()
        }
        // If focused on first link on shift+tab focus on the last link
        if (e.target === firstChild && e.shiftKey && e.key === 'Tab') {
            e.preventDefault()
            lastChild.focus()
        }
    }

    const handleMenuNavigation = (e: any) => {
        if (['Enter', ' '].includes(e.key)) {
            e.preventDefault()
        }
        // If focused on main link icon when "Enter" or "Space" is pressed, do not open submenu if it does not have links just focus view and navigate to skip to content link
        if (e.target === mainLink.current && !props.links && ['Enter', ' '].includes(e.key)) {
            const target = e.target as HTMLAnchorElement
            target.click()
            setSelectedMainLink(e.target)
            setShowLinks(false)
            props.onNavigate?.()
            return
        }

        // If focused on main link icon when "Enter" or "Space" is pressed, open submenu if it has links
        if (e.target === mainLink.current && props.links && ['Enter', ' '].includes(e.key)) {
            setSelectedMainLink(e.target)
            setShowLinks(true)
        }
        // Close submenus when escape key is pressed when focused on any of the navigation elements
        if (e.key === 'Escape') {
            hideNavLinks()
            mainLink?.current?.focus()
        }
    }

    const handleSubMenuNavigation = (e: KeyboardEvent<HTMLElement>) => {
        handleMenuNavigation(e)
        // If focused on sub menu link focus view and navigate to skip to content link
        if (['Enter', ' '].includes(e.key)) {
            e.currentTarget.click()
            setShowLinks(false)
            props.onNavigate?.()
        }
    }

    const onLinkClick = (clearContactOnClick: boolean) => {
        if (clearContactOnClick) {
            dispatch(clearContact())
        }
        hideNavLinks()
    }

    const hasFeature = useHasFeature()
    const hasRole = useHasRole()

    const checkForFeatures = (link: LinkProps) => {
        const linkFeature = link.name as AppFeatures
        if (linkFeature === AppFeatures.ANNOUNCEMENTS) {
            // We only want to use announcements link if agent has announcements sub features
            return hasFeature(linkFeature, link.subfeatures as AppSubFeatures[])
        }
        return hasFeature(linkFeature)
    }

    const { title, links, icon, selected, onClick, path, location } = props
    const hasNoLinks = links && !links.find((l) => l.roles || checkForFeatures(l))
    if (hasNoLinks) return null
    const totalNotificationCount =
        links?.reduce(
            (notificationCount, link) => (notificationCount += link.notificationCount ?? 0),
            0,
        ) ?? 0

    const fullTitle = React.useMemo(() => {
        if (props.formatTitle) {
            return props.formatTitle(title ?? '', totalNotificationCount)
        }

        if (totalNotificationCount > 0) {
            return `${title}. Contains ${totalNotificationCount} ${conditionalPluralise(
                'notification',
                totalNotificationCount,
            )}`
        }

        return title
    }, [props.formatTitle, title, totalNotificationCount])

    return (
        <div
            onClick={showNavLinks}
            onMouseEnter={showNavLinks}
            onMouseLeave={hideNavLinks}
            id={`sa--nav-element-${title?.toLowerCase().replace(/\s/g, '-')}`}
            className={`nav-element ${selected ? 'selected' : ''}`}
            role="presentation"
        >
            {!!path ? (
                <Link
                    title={fullTitle}
                    aria-label={fullTitle}
                    ref={mainLink as React.RefObject<HTMLAnchorElement>} 
                    className="main-link"
                    onKeyUp={handleMenuNavigation}
                    onClick={onClick}
                    to={{ pathname: path, search: location.search }}
                >
                    <div className="icon-container">
                        {icon}
                        {totalNotificationCount > 0 && (
                            <div className="icon-container-notification" />
                        )}
                    </div>
                </Link>
            ) : (
                <Button
                    title={fullTitle}
                    aria-label={fullTitle}
                    innerRef={mainLink as React.RefObject<HTMLButtonElement>}
                    className="main-link"
                    onKeyUp={handleMenuNavigation}
                    onClick={onClick}
                >
                    <div className="icon-container">
                        {icon}
                        {totalNotificationCount > 0 && (
                            <div className="icon-container-notification" />
                        )}
                    </div>
                </Button>
            )}

            {showLinks && (
                <div className="links-container" cy-ref={`${title}-links`}>
                    {title ? (
                        <Link
                            ref={titleLink}
                            onClick={onClick}
                            onBlur={hideNavLinks}
                            onKeyUp={handleMenuNavigation}
                            to={{ pathname: path, search: location.search }}
                            tabIndex={!links ? 0 : -1}
                            className={`title-container ${!links ? 'hoverable' : ''}`}
                        >
                            <p className="element-title">{title}</p>
                        </Link>
                    ) : null}

                    {links ? (
                        <ul className="links" ref={submenu}>
                            {links.map((link, i) => {
                                const allow = link.roles
                                    ? hasRole(link.roles)
                                    : hasFeature(
                                          link.name as AppFeatures,
                                          link.subfeatures as AppSubFeatures[],
                                      )
                                if (!allow) return null
                                const linkHasNotifications = !!link.notificationCount

                                const fullLinkTitle = (function () {
                                    if (props.formatTitle) {
                                        return props.formatTitle(
                                            link.title ?? '',
                                            link.notificationCount ?? 0,
                                        )
                                    }

                                    if (linkHasNotifications) {
                                        return `${link.title}. ${link.notificationCount} ${conditionalPluralise(
                                            'notification',
                                            link.notificationCount,
                                        )}`
                                    }

                                    return link.title
                                })()

                                return (
                                    <li key={i}>
                                        {link.external ? (
                                            <a
                                                ref={childLink}
                                                onKeyUp={handleSubMenuNavigation}
                                                onKeyDown={handleTab}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={link.path}
                                                className="child-link"
                                                title={fullLinkTitle}
                                                aria-label={fullLinkTitle}
                                            >
                                                {link.title}
                                                {linkHasNotifications && (
                                                    <span className="child-link-notification">
                                                        {link.notificationCount}
                                                    </span>
                                                )}
                                            </a>
                                        ) : (
                                            <Link
                                                ref={childLink}
                                                onKeyUp={handleSubMenuNavigation}
                                                onKeyDown={handleTab}
                                                onClick={() =>
                                                    onLinkClick(!!link.clearContactOnClick)
                                                }
                                                to={{
                                                    pathname: link.path,
                                                    search: location.search,
                                                }}
                                                className="child-link"
                                                title={fullLinkTitle}
                                                aria-label={fullLinkTitle}
                                            >
                                                {link.title}
                                                {linkHasNotifications && (
                                                    <span className="child-link-notification">
                                                        {link.notificationCount}
                                                    </span>
                                                )}
                                            </Link>
                                        )}
                                    </li>
                                )
                            })}
                        </ul>
                    ) : null}
                </div>
            )}
        </div>
    )
}

export default withRouter(NavElement)
