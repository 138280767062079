import { Dropdown } from '@missionlabs/smartagent-app-components'
import {
    SADropdownOption,
    SADropdownProps,
} from '@missionlabs/smartagent-app-components/dist/Dropdown'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRTMQueues } from 'store/settings/settings.actions'
import { getRTMQueuesFromState } from 'store/settings/settings.utils'
import RootState from 'store/state'

interface Props extends Omit<SADropdownProps, 'options' | 'onChange'> {
    onSelect: (queue: Queue) => void
    selectedQueueID?: string
    blankOption?: boolean
    filterFn?: (agent: Queue) => boolean
    label?: string
    title?: string
    shouldUseTaggedQueues?: boolean
    className?: string
}

export interface Queue {
    ID: string
    name: string
    arn: string
}

const QueueDropdown: React.FC<Props> = ({
    selectedQueueID,
    onSelect,
    blankOption,
    filterFn,
    label,
    title,
    shouldUseTaggedQueues,
    className,
    ...props
}) => {
    const dispatch = useDispatch()

    const [queues, queuesLoading] = useSelector(getRTMQueuesFromState)
    const userTags = useSelector((state: RootState) => state.user?.tags)

    const options = useMemo<SADropdownOption[]>(() => {
        const sorted = [...(queues ?? [])].sort((a, b) =>
            a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1,
        )

        const mapped: SADropdownOption[] = sorted.map((q) => ({
            label: q.Name,
            data: { ID: q.Id, name: q.Name, arn: q.Arn },
        }))

        if (queuesLoading) {
            mapped.unshift({ label: 'Loading Queues...', data: undefined })
        }

        if (blankOption) {
            mapped.unshift({ label: '', data: undefined })
        }

        return mapped
    }, [queues, queuesLoading, blankOption])

    const selected = useMemo(
        () => queues?.find((a) => a.Id === selectedQueueID),
        [queues, selectedQueueID],
    )

    useEffect(() => {
        if (!queues && userTags) void dispatch(getRTMQueues(userTags))
    }, [queues, userTags])

    return (
        <Dropdown
            {...props}
            className={className || 'sa-dropdown-wrapper'}
            options={filterFn ? options.filter((option) => filterFn(option.data)) : options}
            value={props.value ?? selected?.Name ?? ''}
            title={title ?? 'Select queue'}
            label={label ? label : 'Queue'}
            onChange={(value: SADropdownOption<Queue>) => onSelect(value.data)}
        />
    )
}

export default QueueDropdown
