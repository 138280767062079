var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { PureComponent } from "react";
class Button extends PureComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "state", {
      timer: void 0
    });
    __publicField(this, "onMouseDown", () => {
      if (!this.props.onLongPress)
        return;
      clearTimeout(this.state.timer);
      this.setState({
        timer: setTimeout(this.props.onLongPress, 500)
      });
    });
    __publicField(this, "onMouseUp", () => {
      if (!this.props.onLongPress)
        return;
      clearTimeout(this.state.timer);
    });
    __publicField(this, "onClick", (ev) => {
      if (!this.props.onClick)
        return;
      if (!this.props.disabled)
        this.props.onClick(ev);
    });
  }
  render() {
    const { onClick, small, onMouseDown, onMouseUp, children, onLongPress, styling, className, curved, icon, large, round, elastic, innerRef, ...others } = this.props;
    const buttonClasses = classNames({
      "button": true,
      [`button-${styling}`]: true,
      [className]: !!className,
      "disabled": this.props.disabled,
      "elastic": this.props.elastic,
      "large": large,
      "small": small,
      "round": round
    });
    return /* @__PURE__ */ jsxs("button", { ref: this.props.innerRef, onClick: this.onClick, onMouseDown: this.onMouseDown, onMouseUp: this.onMouseUp, className: buttonClasses, ...others, type: others.type, children: [
      icon || null,
      this.props.children
    ] });
  }
}
__publicField(Button, "defaultProps", {
  className: "",
  styling: "blank",
  large: false,
  onLongPress: () => {
  },
  disabled: false,
  small: false,
  onMouseUp: () => {
  },
  curved: false,
  icon: void 0,
  round: false,
  loading: void 0
});
export {
  Button as default
};
