import React, { useEffect } from 'react'

import { Dropdown } from '@missionlabs/smartagent-app-components'
import {
    SADropdownOption,
    SADropdownProps,
} from '@missionlabs/smartagent-app-components/dist/Dropdown'
import useSettings from 'hooks/redux/useSettings'
import { useDispatch } from 'react-redux'
import { getUsers } from 'store/settings/settings.actions'

interface Props extends Omit<SADropdownProps, 'options' | 'onChange'> {
    onSelect: (agent: Agent | undefined) => void
    selectedAgentID?: string
    blankOption?: boolean
    filterFn?: (agent: Agent) => boolean
    className?: string
}

export interface Agent {
    ID: string
    name: string
}

const AgentDropdown: React.FC<Props> = ({
    selectedAgentID,
    onSelect,
    blankOption,
    filterFn,
    className,
    ...props
}) => {
    const dispatch = useDispatch()

    const { users, usersLoading } = useSettings()

    useEffect(() => {
        if (!users?.length && !usersLoading) dispatch(getUsers())
    }, [users, usersLoading, dispatch])

    const selected = users?.find((a) => a.ID === selectedAgentID)

    const options: SADropdownOption[] =
        users
            ?.map((a) => ({
                label: `${a.firstName} ${a.lastName}`,
                data: {
                    ID: a.ID,
                    name: `${a.firstName} ${a.lastName}`,
                },
            }))
            .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)) ?? []

    if (usersLoading) {
        options.unshift({
            label: `Loading ${props.label || 'Agent'}s...`,
            data: undefined,
        })
    } else if (blankOption) {
        options.unshift({
            label: '',
            data: undefined,
        })
    }

    return (
        <Dropdown
            {...props}
            className={className || 'sa-dropdown-wrapper'}
            options={filterFn ? options.filter((option) => filterFn(option.data)) : options}
            value={props.value ?? (selected ? `${selected.firstName} ${selected.lastName}` : '')}
            label={props.label ?? 'Agent'}
            title={props.title ?? 'Select agent'}
            onChange={(value: SADropdownOption<Agent>) => onSelect(value.data)}
            ariaLabel={props['ariaLabel']}
        />
    )
}

export default AgentDropdown
