import './agents.scss'

import { UserConversation } from 'graphql/types'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { BeatLoader } from 'react-spinners'
import { AgentInfo } from 'store/agents/agents.state'
import { IHierarchyFilter } from 'store/metrics/metrics.state'
import RootState from 'store/state'

import { Button, Input } from '@missionlabs/smartagent-app-components'
import Tabs, { Tab } from '@missionlabs/smartagent-app-components/dist/Tabs'
import HierarchiesDropdown from 'components/Dropdowns/hierarchies'
import RoutingProfileDropdown from 'components/Dropdowns/routing-profiles'
import Status from 'components/Status'
import { getAgents, setAgentsFilter } from 'store/agents/agents.actions'
import TimeInState from 'views/Agents/TimeInState'
import useHasFeature, { AppFeatures, AppSubFeatures } from 'hooks/useHasFeature'

interface Props extends ReturnType<typeof mapStateToProps> {
    userConversations?: UserConversation[]
    onCreateConversation?(users: string[]): Promise<string> //TODO Use actual return type
}

const Agents: React.FC<Props> = ({ agents, states, agentsError, agentsLoading, agentsFilter, user, auth }) => {
    const dispatch = useDispatch()
    const hasFeature = useHasFeature()
    
    const isHierarchyFilterDerestricted = hasFeature(
        AppFeatures.AGENTS_DIRECTORY,
        AppSubFeatures.DIRECTORY_DERESTRICT_AGENT_HIERARCHY_FILTERING,
    )
    const username = user?.username
    const { hierarchyStructure } = auth

    const [search, setSearch] = useState('')

    const input = React.createRef<HTMLInputElement | HTMLTextAreaElement>()

    useEffect(() => {
        if (hierarchyStructure) {
            dispatch(
                setAgentsFilter({
                    hierarchyStructure,
                }),
            )
        }

        if (!isHierarchyFilterDerestricted && !hierarchyStructure) {
            return
        }

        dispatch(getAgents())

        const id = setInterval(() => {
            dispatch(getAgents())
        }, 10000)
        
        setTimeout(() => {
            input?.current?.focus() //Focus on the input after the slide has fully opened.
        }, 500)

        return () => {
            id && clearInterval(id)
        }
    }, [dispatch, hierarchyStructure])

    const isHierarchyFilterDisabled = !user?.hierarchyStructure && !isHierarchyFilterDerestricted

    const onRouting = (routingProfileID?: string) => {
        dispatch(
            setAgentsFilter({
                routingProfileIDs: routingProfileID,
            }),
        )
        dispatch(getAgents())
    }

    const onHierarchySelect = (data?: IHierarchyFilter) => {
        const getFilterValue = () => {
            if (isHierarchyFilterDerestricted) {
                return data?.hierarchyStructureIDs.join('/')
            } else {
                const hsArray = user?.hierarchyStructure?.split('/')
                hsArray?.pop()

                return hsArray?.concat(data?.hierarchyStructureIDs!).join('/')
            }
        }

        dispatch(
            setAgentsFilter({
                hierarchyStructure: getFilterValue(),
            }),
        )

        dispatch(getAgents())
    }

    const onHierarchyClear = () => {
        const hierarchyStructure = isHierarchyFilterDerestricted ? '' : user?.hierarchyStructure

        dispatch(
            setAgentsFilter({
                hierarchyStructure,
            }),
        )

        dispatch(getAgents())
    }

    const sortByAgentState = (theArray: any) => {
        const sortedStates = states.filter((s) => s.name !== 'Available' && s.type !== 'offline')
        sortedStates.unshift(states.find((s) => s.name === 'Available')!)
        sortedStates.push(states.find((s) => s.type === 'offline')!)

        return theArray
            .sort((a: any, b: any) => {
                const aIndex = sortedStates.findIndex((s) => s.name === a.state)
                const bIndex = sortedStates.findIndex((s) => s.name === b.state)
                return aIndex > bIndex ? 1 : -1
            })
            .filter((agent: AgentInfo) => username !== agent.username)
    }

    const sortAgents = (sortType: string) => {
        let allAgents = agents
        if (search) {
            allAgents = allAgents.filter((agent: AgentInfo) =>
                agent?.name?.toLowerCase().includes(search.toLowerCase()),
            )
        }

        if (sortType === 'name') {
            return [...allAgents]
                .sort((a: any, b: any) => {
                    return (a.name?.toLowerCase() ?? '') > (b.name?.toLowerCase() ?? '') ? 1 : -1
                })
                .filter((agent: AgentInfo) => username !== agent.username)
        } else {
            const available = sortByAgentState(
                allAgents.filter(
                    (agent: AgentInfo) =>
                        agent.state === 'Available' && agent.call_state !== 'MISSED',
                ),
            )
            const missed = sortByAgentState(
                allAgents.filter((agent: AgentInfo) => agent.call_state === 'MISSED'),
            )
            const online = [...available, ...missed]
            const offline = sortByAgentState(
                allAgents.filter((agent: AgentInfo) => agent.state !== 'Available'),
            )
            return [...online, ...offline]
        }
    }

    const renderTab = (tabTitle: string, sortType: string) => {
        return (
            <Tab title={tabTitle}>
                {agentsError ? (
                    <div className="agents-error">
                        There was an error <br />
                        <Button elastic onClick={getAgents}>
                            <u>Try again</u>
                        </Button>
                    </div>
                ) : agentsLoading && !agents.length ? (
                    <div className="agents-loading">
                        <BeatLoader color="#212121" />
                    </div>
                ) : (
                    sortAgents(sortType).map((agent) => (
                        <div className="agent-status" key={agent.agent_id}>
                            <div className="agent-details">
                                <span className="agent-details-name">
                                    {agent.name || agent.username.split(':').slice(-1)[0]}
                                </span>
                                {agent.state_start_timestamp && (
                                    <span className="agent-details-time">
                                        <Status
                                            isAvailable={
                                                agent.state === 'Available' &&
                                                agent.call_state !== 'MISSED'
                                            }
                                            statusName={
                                                agent.call_state === 'ENDED'
                                                    ? 'After contact work'
                                                    : agent.call_state === 'MISSED'
                                                      ? 'Missed Call'
                                                      : agent.on_call
                                                        ? 'On contact'
                                                        : agent.state
                                            }
                                        />
                                        <TimeInState
                                            beenInStateFor={
                                                agent.on_call || agent.call_state === 'MISSED'
                                                    ? agent.call_state_start_timestamp
                                                    : agent.state_start_timestamp
                                            }
                                        />
                                    </span>
                                )}
                            </div>
                        </div>
                    ))
                )}
            </Tab>
        )
    }

    return (
        <>
            <aside title="Agent Directory" className="agents">
                <div className="agents-filterdiv">
                    <div className="agents-filters">
                        <Input
                            ref={input}
                            name="Agent search"
                            placeholder="Search agents..."
                            onChange={(val: any) => setSearch(val)}
                            value={search}
                            className="agents-search-input"
                        />
                    </div>

                    <div className="agents-filters">
                        <HierarchiesDropdown
                            disabled={isHierarchyFilterDisabled}
                            onClear={onHierarchyClear}
                            onSelect={onHierarchySelect}
                            selectParents
                            autoValue
                            selectedHierarchyID={agentsFilter?.hierarchyStructure}
                        />
                    </div>

                    <div className="agents-filters">
                        <RoutingProfileDropdown
                            blankOption
                            onSelect={(option) => onRouting(option?.Id)}
                            selectedRoutingProfileID={agentsFilter?.routingProfileIDs}
                        />
                    </div>
                </div>

                <Tabs>
                    {renderTab('Sort by name', 'name')}
                    {renderTab('Sort by status', 'status')}
                </Tabs>
            </aside>
        </>
    )
}

function mapStateToProps(state: RootState) {
    return {
        ...state.agents,
        routingProfile: state.user?.routingProfile,
        states: state.user?.states ?? [],
        agentExpiry: state.app?.appConfig.agentExpiry,
        userHierarchy: state.settings?.userHierarchy,
        user: state.user,
        auth: state.auth
    }
}

export default connect(mapStateToProps)(Agents)
