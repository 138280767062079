import { jsx } from "react/jsx-runtime";
import { isValid as isValid$1 } from "date-fns";
import { forwardRef, useState, useEffect } from "react";
import { Input } from "./Input.mjs";
const getLocalDate = (value) => {
  const date = isDate(value) ? value : isValid(value) ? new Date(value) : /* @__PURE__ */ new Date();
  const offsetDate = date.getTime() - getTimezoneOffsetMillis(date);
  return new Date(offsetDate);
};
const isDate = (value) => value instanceof Date;
const isValid = (value) => isValid$1(value) && value !== void 0 && value !== null;
const getTimezoneOffsetMillis = (date) => {
  return date.getTimezoneOffset() * 6e4;
};
const ReactDatePicker = forwardRef(
  ({ handleChange: _handleChange, onChange, value: _value, ...props }, ref) => {
    const [value, setValue] = useState(() => {
      const date = getLocalDate(_value);
      return date.toISOString().split("T")[0];
    });
    function handleChange(value2) {
      const handler = _handleChange ?? onChange;
      const dateValue = new Date(value2);
      if (isValid(dateValue))
        handler == null ? void 0 : handler(dateValue);
      setValue(value2);
    }
    useEffect(() => {
      handleChange(value);
    }, []);
    return /* @__PURE__ */ jsx(
      Input,
      {
        ...props,
        ref,
        type: "date",
        style: { ...props.style ?? {}, height: "40px" },
        value,
        onChange: handleChange
      }
    );
  }
);
ReactDatePicker.displayName = "ReactDatePicker";
export {
  ReactDatePicker as default
};
