import type { Middleware } from '@reduxjs/toolkit'
import { CCP_INIT_TIMEOUT_MS } from 'constants/index'
import { reportIssue } from 'services/api/api.company'
import { updateContactAttributes } from 'services/api/api.contact'
import {
    ccpLogout,
    reportIssue as reportIssueAction,
    selectInstance,
    setCallProviderInitStartTime,
} from 'store/app/app.reducer'
import * as ContactReducer from 'store/contact/contact.reducer'
import { ICallContactAttributes } from 'store/contact/contact.state'
import { contactAttributes } from 'store/contact/contact.utils'
import RootState from 'store/state'
import * as UserActions from 'store/user/user.actions'
import * as UserReducer from 'store/user/user.reducer'
import { isAnyAction } from 'utils'
import Logger from '../../../logger'

//@ts-ignore
window.connect = connect

let w: Window | null

//enforce v2
const getCCPURL = (ccpURL: string) =>
    !ccpURL.match(/connect\/ccp-v2/g) ? ccpURL.replace(/connect\/ccp/g, 'connect/ccp-v2') : ccpURL

function openCCPWindow(ccpURL: string) {
    const getTop = () => window.innerHeight / 2 - 290
    const getLeft = () => window.innerWidth / 2 - 200

    w = window.open(
        getCCPURL(ccpURL),
        'ccp',
        `toolbar=no,height=600,width=400,top=${getTop() + window.screenY},left=${
            getLeft() + window.screenX
        }`,
    )
    if (w) {
        w.focus()
    }
}

export const getCCPIframeSrc = (ccpURL: string) => {
    if (ccpURL.includes('awsapps')) {
        return ccpURL.replace(/\/connect(.*)$/, '/connect/request-storage-access')
    }
    return ccpURL.replace(/.aws\/(.*)$/, '.aws/request-storage-access')
}

const ccpAppMiddleware: Middleware<{}, RootState> = (store) => (next) => (action) => {
    if (!isAnyAction(action)) return

    if (store.getState().app?.callProvider === 'kumodi') {
        return next(action)
    }

    switch (action.type) {
        case selectInstance.type: {
            if (store.getState().global.saIsAlreadyOpen) return next(action)

            const { instance, appConfig } = action.payload
            const app = document.getElementById('app')
            const ccpUrl = getCCPURL(instance.ccpURL)
            connect.core.initCCP(app!, {
                ccpUrl,
                loginPopup: false,
                region: instance.region,
                softphone: {
                    allowFramedSoftphone: appConfig.allowFramedSoftphone ? true : false,
                },
                //@ts-ignore
                ccpAckTimeout: appConfig.ccpAckTimeout, //optional, defaults to 3000 (ms)
                //@ts-ignore
                ccpSynTimeout: appConfig.ccpSynTimeout, //optional, defaults to 1000 (ms)
                //@ts-ignore
                ccpLoadTimeout: appConfig.ccpLoadTimeout, //optional, defaults to 5000 (ms)
            })

            store.dispatch(setCallProviderInitStartTime(Date.now()))

            connect.core.initSoftphoneManager({ allowFramedSoftphone: true })

            connect.core.onAccessDenied(function () {
                //This happens when a user does not have CCP:All set in connect - they are authenticated but we cannot load the user
                if (store.getState().auth.authenticated && !store.getState().user) {
                    store.dispatch(UserActions.authError('User does not have correct permissions'))
                }
            })

            connect.core.onAuthFail(() => {
                store.dispatch(ccpLogout())
                setTimeout(() => {
                    window.location.reload()
                }, 500)
            })

            return next(action)
        }
        case UserReducer.authExternalUserRequest.type: {
            //Login in with cognito, ad etc, SA Api has returned us a token for CCP, we use it to login via our iframe
            const iframe = document.getElementById('sa-login-iframe') as HTMLIFrameElement

            const { callProviderInitStartTime } = store.getState().app

            if (callProviderInitStartTime) {
                const currentTime = Date.now()

                if (currentTime - callProviderInitStartTime > CCP_INIT_TIMEOUT_MS) {
                    return window.location.reload()
                }
            }

            try {
                iframe.contentWindow!.postMessage(
                    {
                        type: 'smartagent-login',
                        credentials: action.payload.credentials,
                        ccpURL: getCCPURL(action.payload.ccpURL),
                    },
                    '*',
                )
            } catch (error) {
                Logger.error('SMARTAGENT-LOGIN-ERROR', error)
                console.log('Error posting init: ', error)
            }
            return next(action)
        }

        case UserReducer.login.type: {
            const { instance, identityManagement } = store.getState().app
            if (!instance) return next(action)

            if (identityManagement && identityManagement !== 'connect') {
                return next(action)
            }

            w ? w.focus() : openCCPWindow(instance.ccpURL)

            return next(action)
        }

        case UserReducer.refresh.type:
            store.dispatch(UserActions.setAvailable())
            setTimeout(() => {
                //refresh the page
                window.location.href = '.'
            }, 1000)
            return next(action)

        case UserReducer.logout.type: {
            store.dispatch(UserActions.setNotAvailable())
            const eventBus = connect.core.getEventBus()
            eventBus.trigger(connect.EventType.TERMINATED)
            connect.core.terminate()

            const url = store.getState().app.instance!.ccpURL
            const iframe = document.querySelector(
                `iframe[src="${getCCPIframeSrc(url)}"]`,
            ) as HTMLIFrameElement
            // The logout endpoint does not need ccp-v2connect
            // const logoutUrl = (url.split('connect/')[0] + 'connect/logout').replace('ccp-v2connect/','')

            const connectURL = new URL(url)
            connectURL.pathname = '/connect/logout'
            const logoutUrl = connectURL.toString()

            iframe.src = logoutUrl

            setTimeout(() => {
                window.location.reload()
            }, 500)
            return next(action)
        }
        case UserReducer.userLoaded.type:
            w?.close()
            return next(action)

        case ContactReducer.updateContactAttributes.type: {
            next(action)
            const { contact, app, auth, call, user, chat, tasks } = store.getState()

            if (!action.payload || (!contact?.ID && !action.payload?.ID)) return
            //Don't update contact if we are not in a call or in after call work and not forcing the update
            if (!action.payload.commitUpdate) {
                if (contact?.channel === 'VOICE' && !call && user?.status.name !== 'AfterCallWork')
                    return
                if (
                    contact?.channel === 'CHAT' &&
                    !chat.connections.find((chat) => contact.ID === chat.id)
                )
                    return
                if (
                    contact?.channel === 'TASK' &&
                    !tasks.connections.find((task) => contact.ID === task.id)
                )
                    return
            }
            //Only certain fields will be updated on the CTR

            const updateFields = [
                'sa-acw-',
                'sa-DPA',
                'sa-cm-od-',
                ...contactAttributes,
                ...(app.appConfig.contactAttributes || []),
            ]
            const attributes = Object.keys(action.payload.attributes).reduce(
                (attributes: ICallContactAttributes, key: string) => {
                    //Check for matches - can either start sa- or not eg ['externalID'] matches sa-externalID and externalID
                    const hasMatches = updateFields.find(
                        (field) => key.indexOf(field) === 0 || key.indexOf(field) === 3,
                    )
                    if (hasMatches) {
                        attributes[key] = action.payload.attributes[key]?.toString()
                    }
                    return attributes
                },
                {} as ICallContactAttributes,
            )

            if (!Object.keys(attributes).length) return

            return updateContactAttributes(
                app.ID,
                app.instance!.ID,
                contact?.originalContactID || action.payload.ID,
                auth.token!,
                attributes,
            ).catch()
        }

        case reportIssueAction.type: {
            const state = store.getState()
            reportIssue(state.app.ID, {
                description: action.payload,
                state,
                //@ts-ignore
                // hacky fix for getting connect logs
                connectLogs: (connect.getLog() as any)._logs,
            }).then(() => {
                return next(action)
            })
            return
        }

        default:
            return next(action)
    }
}

export default ccpAppMiddleware
