import { Dropdown } from '@missionlabs/smartagent-app-components'
import {
    SADropdownOption,
    SADropdownProps,
} from '@missionlabs/smartagent-app-components/dist/Dropdown'
import useSettings from 'hooks/redux/useSettings'
import useUser from 'hooks/redux/useUser'
import useHasFeature, { AppFeatures, AppSubFeatures } from 'hooks/useHasFeature'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { IHierarchyFilter } from 'store/metrics/metrics.state'
import { getUserHierarchy } from 'store/settings/settings.actions'
import { getHierarchiesOfUserLevelAndBelow, userHierarchyToDropdown } from 'utils'

interface Props extends Omit<SADropdownProps, 'options' | 'onChange'> {
    onSelect(hierarchy: IHierarchyFilter): void
    onClear?: () => void
    selectedHierarchyID?: string
    filterFn?: (hier: IHierarchyFilter) => boolean
    autoValue?: boolean
}

const HierarchiesDropdown: React.FC<Props> = ({
    selectedHierarchyID,
    filterFn,
    onSelect,
    value,
    autoValue,
    onClear,
    ...props
}) => {
    const { userHierarchy } = useSettings()
    const dispatch = useDispatch() 
    const hasFeature = useHasFeature()
    const { hierarchyStructure: userHierarchyStructure } = useUser() ?? {}

    const isHierarchyFilterDerestricted = hasFeature(
        AppFeatures.AGENTS_DIRECTORY,
        AppSubFeatures.DIRECTORY_DERESTRICT_AGENT_HIERARCHY_FILTERING,
    )

    const hierarchyStructureForRendering = useMemo(() => {
        if (isHierarchyFilterDerestricted) {
            return userHierarchy
        } else {
            if (userHierarchy && userHierarchyStructure) {
                return getHierarchiesOfUserLevelAndBelow(userHierarchy, userHierarchyStructure)
            }
        }
    }, [isHierarchyFilterDerestricted, userHierarchyStructure, userHierarchy])

    const options = useMemo(() => {
        return userHierarchyToDropdown(hierarchyStructureForRendering)
    }, [hierarchyStructureForRendering])

    useEffect(() => {
        if (!userHierarchy) {
            dispatch(getUserHierarchy())
        }
    }, [userHierarchy, dispatch])

    const getAutoValue = () => {
        if (!selectedHierarchyID || !userHierarchy) return
        const parts = selectedHierarchyID.split('/')
        return parts
            .map((p, i) => {
                switch (i) {
                    case 0:
                        return userHierarchy?.LevelOne.groups.find((g) => g.Id === p)?.Name
                    case 1:
                        return userHierarchy?.LevelTwo?.groups.find((g) => g.Id === p)?.Name
                    case 2:
                        return userHierarchy?.LevelThree?.groups.find((g) => g.Id === p)?.Name
                    case 3:
                        return userHierarchy?.LevelFour?.groups.find((g) => g.Id === p)?.Name
                    case 4:
                        return userHierarchy?.LevelFive?.groups.find((g) => g.Id === p)?.Name
                    default:
                        return ''
                }
            })
            .join(' / ')
    }

    return (
        <Dropdown
            {...props}
            title={props.title ?? 'Select agent hierarchy'}
            label={props.label ?? 'Agent hierarchy'}
            value={autoValue ? getAutoValue() : value}
            filter
            options={options}
            onClear={onClear}
            hierarchyClear={true}
            onChange={(value: SADropdownOption<IHierarchyFilter>) => onSelect(value.data)}
        />
    )
}

export default HierarchiesDropdown
