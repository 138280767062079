import { H, Level } from 'react-accessible-headings'
import './chat.scss'

import { Channel } from '@aws-sdk/client-connect'
import classNames from 'classnames'
import { Loader } from 'components'
import RecordingControl from 'components/RecordingControl'
import Tabs, { SATab } from 'components/Tabs'
import useAppConfig from 'hooks/redux/useAppConfig'
import useChatConnections from 'hooks/redux/useChatConnections'
import useContact from 'hooks/redux/useContact'
import useContactLogForm from 'hooks/useContactLogForm'
import useHasFeature, { AppFeatures, useGetFeatureConfig } from 'hooks/useHasFeature'
import useSize from 'hooks/useSize'
import React, { useEffect, useMemo, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { completeChatACW, setSelectedChat } from 'store/chat/chat.actions'
import { setContact } from 'store/contact/contact.actions'
import { removeContactHistory } from 'store/contactHistory/contactHistory.actions'
import { setRedirect } from 'store/global/global.actions'
import RootState from 'store/state'
import { isContactLogEmpty, setCSSVar } from 'utils'
import { ContactPanel } from 'views/ContactPanel'
import CallHistory from 'views/Home/CallHistory'
import ContactLog from 'views/Home/ContactLog'
import CompleteWrapUp from 'views/Home/ContactLog/complete-wrap-up'
import CustomerInputs from 'views/Home/CustomerInputs'
import DPA from 'views/Home/DPA'

import updateDocumentTitle from 'utils/updateDocumentTitle'
import ChatController from './ChatController'
import ActiveChatMessageView from './ChatMessenger/ActiveChatMessageView'
import ChatMessengerControls from './ChatMessenger/ChatMessengerControls'
import SocialChatMessages from './ChatMessenger/SocialChatMessages'

interface Props {}

const generateTabs = (acw?: boolean, alwaysShowContactLog?: boolean) => {
    const chatTab = {
        id: 'chat',
        content: 'Chat',
    }
    const contactLogTab = {
        id: 'log',
        content: 'Contact Log',
    }
    if (!acw && !alwaysShowContactLog) return [chatTab]
    return [chatTab, contactLogTab]
}

const Chat: React.FC<Props> = () => {
    updateDocumentTitle('Chat - SmartAgent')

    const selectedChat = useChatConnections('selected')
    const chats = useChatConnections()
    const contact = useContact()
    const [tab, setTab] = useState('chat')
    const hasFeature = useHasFeature()
    const contactLog = useContactLogForm()
    const noContactLogOrEmpty = isContactLogEmpty(contactLog)
    const appConfig = useAppConfig()
    const [isLoadingMessages, setIsLoadingMessages] =
        useState(selectedChat?.isLoadingTranscripts) || false

    const chatFeatures = useGetFeatureConfig()(AppFeatures.CHAT)

    const uniqueSocialId = useSelector(
        (state: RootState) => state.contact?.attributes['sa-customer-endpoint-id'],
    )

    //chat panel size
    const {
        size: { width },
        ref,
    } = useSize<HTMLDivElement>()

    const dispatch = useDispatch()

    useEffect(() => {
        setCSSVar('--sa-quick-replies-left', width + 'px')
        if (chatFeatures?.chatPanelWidthPercent) {
            setCSSVar('--sa-chat-panel-width', chatFeatures.chatPanelWidthPercent + '%')
        }
    }, [width, chatFeatures])

    useEffect(() => {
        setIsLoadingMessages(selectedChat?.isLoadingTranscripts)
        if (selectedChat || location.pathname !== '/chat') return
        //Try route to another chat
        const nonConnectingChats = chats.filter(
            (c) => c.status !== connect.ContactStateType.CONNECTING,
        )
        if (nonConnectingChats.length) {
            dispatch(setSelectedChat(nonConnectingChats[0].id))
        } else {
            dispatch(setRedirect('/chat'))
        }
    }, [selectedChat])

    useEffect(() => {
        if (selectedChat?.acw) {
            setTab('log')
        } else {
            setTab('chat')
        }
    }, [selectedChat?.acw])

    useEffect(() => {
        if (!contact && chats.length > 0) {
            dispatch(setSelectedChat(chats[0].id))
        }
        if (contact?.channel !== Channel.CHAT) {
            dispatch(setContact(null))
        }
    }, [contact])

    const onEndACW = () => {
        // Temporary batch wrap until we've fully migrated to React 18
        batch(() => {
            uniqueSocialId &&
                dispatch(
                    removeContactHistory({
                        attributeSearchName: 'CHAT',
                        attributeSearchValue: uniqueSocialId,
                    }),
                )
            dispatch(completeChatACW(selectedChat?.id!))
        })
    }

    const alwaysShowContactLog = appConfig?.alwaysShowContactLog || false
    const ChatTabs: SATab[] = useMemo(
        () => generateTabs(selectedChat?.acw, alwaysShowContactLog),
        [selectedChat],
    )

    const showRecordingControl = selectedChat && !selectedChat.acw

    return (
        <>
            <H className="only-visible-to-screen-readers" id="skip-to-content">
                Chat
            </H>
            <Level>
                <div className="row chat-home">
                    <div
                        id="chat-container"
                        className={classNames({
                            'chat-panel panel fixed-height row column': true,
                            acw: selectedChat?.acw,
                        })}
                    >
                        {!!selectedChat ? (
                            <>
                                <ChatController />

                                {hasFeature(AppFeatures.DPA) && <DPA />}
                                {hasFeature(AppFeatures.CUSTOMER_INPUTS) && <CustomerInputs />}

                                {showRecordingControl && <RecordingControl />}

                                <Tabs
                                    className="sa-chat-tabs"
                                    selected={tab}
                                    tabs={ChatTabs}
                                    onClick={setTab}
                                />
                                {tab === 'chat' && (
                                    <>
                                        {selectedChat.socialMediaPlatform ? (
                                            isLoadingMessages ? (
                                                <Loader />
                                            ) : (
                                                <SocialChatMessages />
                                            )
                                        ) : (
                                            <ActiveChatMessageView />
                                        )}
                                        <ChatMessengerControls />
                                    </>
                                )}

                                {tab === 'log' && (
                                    <>
                                        <ContactLog
                                            channel="CHAT"
                                            hideHeader
                                            acw={selectedChat?.acw}
                                            onSubmit={onEndACW}
                                        />
                                        {noContactLogOrEmpty && (
                                            <div className="md-mar">
                                                <CompleteWrapUp onComplete={onEndACW} />
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            hasFeature(AppFeatures.CALL_HISTORY) && <CallHistory channel="CHAT" />
                        )}
                    </div>
                    <ContactPanel channel="CHAT" />
                </div>
            </Level>
        </>
    )
}

export default Chat
