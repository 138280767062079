import { AxiosError } from 'axios';
import { CONTACT_LOG } from 'constants/index';
import { AppFeatures } from 'hooks/useHasFeature';
import type { ThunkDispatch } from '@reduxjs/toolkit';
import { getWebsocketUrl } from 'services/api/api.chat';
import * as CompanyAPI from 'services/api/api.company';
import { getIsEnhancedMonitoringEnabled } from 'services/api/api.connect';
import { getContactLog, getDefaultContactLog } from 'services/api/api.contactLog';
import { IAppFeature } from 'store/settings/settings.state';
import { logout } from 'store/user/user.actions';
import { IContactLog } from 'views/AdminSettings/ContactLogs/types';

import { gtag, init as initProviders } from '@missionlabs/analytics-core';
import bchat from '@missionlabs/smartagent-bchat';

import { getEnvironment, getParameterByName, getSubdomain } from '../../utils';
import RootState from '../state';
import * as AppActions from './app.reducer';
import { AppConfig, Instance, InstanceSettings } from './app.state';

export function initApp() {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, AppActions.AppAction>,
        getState: () => RootState,
	) => {
		// Added ReGex to only allow alphanumeric characters, hyphens, and underscores in companyID
        let companyID = (getParameterByName('companyID') || getSubdomain())?.replace(/[^a-zA-Z0-9-_]/g, '');
        if (!companyID) {
            return dispatch(AppActions.appError())
        }

        dispatch(AppActions.initAppLoading())

        try {
            const groupCompany = getParameterByName('groupCompany')

            if (groupCompany) {
                companyID = groupCompany
            } else {
                // check if we're in a group
                const groupSplit = companyID!.split('-')

                if (groupSplit[1] === 'group') {
                    const groups = await CompanyAPI.getCompanyGroup(groupSplit[0])
                    return dispatch(AppActions.setCompanyGroups(groups.data))
                }
            }

            const company = await CompanyAPI.getCompany(companyID!)

            const chatFeature = company.features.find(
                (feature: IAppFeature) => feature.ID === AppFeatures.CHAT,
            )

            if (chatFeature) {
                try {
                    const { websocketUrl, apiKey } = await getWebsocketUrl(company.ID)

                    if (websocketUrl) {
                        window.bchat = await bchat({
                            connectionTimeoutMs: 7000,
                            wsUrl: websocketUrl + '?Authorization=' + apiKey,
                            onReconnect: () => {
                                // refresh ws connectionId for each chat
                                getState().chat.connections.forEach((chat) => {
                                    window.bchat.getChatSession(
                                        chat.initialContactId ?? chat.id,
                                        'AGENT',
                                    )
                                })
                            },
                        })
                    }
                } catch (err) {
                    console.log('error getting bchat websocket url, cannot initialise bchat', err)
                }
            }

            const analyticsFeature = company.features.find(
                (feature: IAppFeature) => feature.ID === AppFeatures.COLLECT_ANALYTICS,
            )

            if (analyticsFeature) {
                initProviders([
                    {
                        provider: gtag,
                        options: {
                            id: 'G-2SMJ7QV0BK',
                            debug: getEnvironment() === 'dev',
                            configSettings: {
                                send_page_view: false,
                                app_name: 'smartagent-app',
                            },
                        },
                    },
                ])
            }

            const identityManagement = getParameterByName('identityManagement')

            if (identityManagement) company.identityManagement = identityManagement
            // here the data about the company gets into redux
            dispatch(AppActions.initAppSuccess(company))

            const instanceKey = getParameterByName('instanceKey')

            const instances = company.instances

            //If we have a refreshToken then use the instance in store
            const isAuthed = !!getState().auth.refreshToken

            const instance =
                instances.length === 1
                    ? instances[0]
                    : instanceKey
                      ? instances.find((inst: any) => inst.key === instanceKey)
                      : isAuthed
                        ? getState().app.instance
                        : null

            //No instance selected to let SelectInstance handle this
            if (!instance) {
                return dispatch(AppActions.clearInstance())
            }

            return dispatch(selectInstance(instance, company.appConfig))
        } catch (err) {
            console.warn(err)
            dispatch(AppActions.appError())
        }
    }
}

export function selectInstance(instance: Instance, appConfig: AppConfig) {
    return async (dispatch: ThunkDispatch<RootState, undefined, AppActions.AppAction>) => {
        let isEnhancedMonitoringEnabled = false

        try {
            const groupCompany = getParameterByName('groupCompany')
			const companyID = (groupCompany || getParameterByName('companyID') || getSubdomain())?.replace(
                /[^a-zA-Z0-9-_]/g,
                ''
            );

            // Check the Connect instance to determine if enhanced call monitoring is enabled
            const { Value } = await getIsEnhancedMonitoringEnabled(companyID!, instance.ID);

			isEnhancedMonitoringEnabled = Value == 'true' ? true : false;
        } catch (err) {
            console.log('error getting enhanced monitoring status', err)
        }

		const settings = { ...instance.settings, isEnhancedMonitoringEnabled }
		
        localStorage.setItem('serviceRegion', instance.serviceRegion ?? '');

        return dispatch(
            AppActions.selectInstance({ instance: { ...instance, settings }, appConfig }),
        )
    }
}

export function loadForm(name: string, formGroup?: string) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, AppActions.AppAction>,
        getState: () => RootState,
    ) => {
        const {
            app: { instance, ID, features },
            auth: { token },
        } = getState()

        let key = formGroup ? `${name}-${formGroup}` : name
        const isContactLogsFeatureEnabled = !!features.find(
            (feature) => feature.ID === 'contact-logs',
        )
        if (key === CONTACT_LOG && isContactLogsFeatureEnabled && !formGroup)
            key = `${name}-default`

        try {
            dispatch(AppActions.loadingForm(key))

            let form
            if (name === CONTACT_LOG)
                form = await handleContactLogForm(
                    instance!,
                    ID,
                    token!,
                    key,
                    isContactLogsFeatureEnabled,
                    formGroup,
                )
            else form = await CompanyAPI.getFormTemplate(ID, name, token!, formGroup)

            if (!form) {
                return dispatch(AppActions.formNotFound(key))
            } else {
                dispatch(AppActions.formLoaded({ name: key, form }))
            }
        } catch (err) {
            const error = err as AxiosError
            if (error.response?.status === 403) return dispatch(logout())
            if (error.response?.status === 404) {
                return dispatch(AppActions.formNotFound(key))
            }

            return dispatch(AppActions.formError(key))
        }
    }
}

const handleContactLogForm = async (
    instance: Instance,
    ID: string,
    token: string,
    key: string,
    isContactLogsFeatureEnabled: boolean,
    formGroup?: string,
): Promise<IContactLog> => {
    let contactLog: IContactLog
    // Feature flag is on.
    if (isContactLogsFeatureEnabled) {
        // Either get default or specified log.
        if (key === `${CONTACT_LOG}-default`) {
            contactLog = await getDefaultContactLog(ID, instance?.ID!, token!)
        } else {
            contactLog = await getContactLog(ID, instance?.ID!, formGroup!, token!)
        }
        // Feature flag is off.
    } else {
        // Either get from S3 with added path specified as the formGroup, or just contact-log.json.
        if (formGroup) {
            contactLog = await getContactLog(ID, instance?.ID!, CONTACT_LOG, token!, formGroup)
        } else {
            contactLog = await getContactLog(ID, instance?.ID!, CONTACT_LOG, token!)
        }
    }
    return contactLog
}

export function updateInstanceSettings(settings: Partial<InstanceSettings>) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, AppActions.AppAction>,
        getState: () => RootState,
    ) => {
        try {
            dispatch(AppActions.updateKeywordsLoading(true))

            const companyID = getState().app.ID
            const token = getState().auth.token!
            const instanceID = getState().app.instance?.ID!

            dispatch(AppActions.updateInstanceSettings(settings))

            const response = await CompanyAPI.putInstanceSettings(
                companyID,
                token,
                instanceID,
                settings,
            )

            dispatch(AppActions.updateKeywordsSuccess(response.status === 200))
        } catch (err) {
            console.log('error updating settings', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())
            if (e) return dispatch(AppActions.updateKeywordsError(true))
        } finally {
            dispatch(AppActions.updateKeywordsLoading(false))
        }
    }
}

export const {
    ccpLogout,
    hidePlugin,
    reportIssue,
    resetPlugin,
    showPlugin,
    updateTitleTag,
    updateKeywordsError,
    appError,
    toggleIframe,
} = AppActions
