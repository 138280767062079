export interface ISessionManager {
    getSessionManager: () => ISessionManagerInstance
    create: () => ISessionManagerInstance
}

export interface ISessionManagerInstance {
    lockSession: () => void
    isLockedOut: () => boolean
    init: () => Promise<void>
    ID: string
}

export const SA_LOCKED_STORAGE_KEY = 'SASessionLockID'
export const SA_LOCK_ERROR = 'Could not lock the session as it is already locked'

const MAX_INIT_TIME_DELAY_MS = 1000

const createSessionManager = (ID: string) => {
    const lockSession = () => {
        if (!isLockedOut()) {
            lock()
            return
        }

        console.error(SA_LOCK_ERROR)
    }

    const lock = () => {
        window.localStorage.setItem(SA_LOCKED_STORAGE_KEY, ID)
        window.addEventListener('storage', restoreLock)
    }

    const releaseSession = () => {
        window.removeEventListener('storage', restoreLock)
        window.localStorage.removeItem(SA_LOCKED_STORAGE_KEY)
    }

    const isLockedOut = () => {
        const value = window.localStorage.getItem(SA_LOCKED_STORAGE_KEY)
        return !!value && value !== ID
    }

    const restoreLock = (event: StorageEvent) => {
        if (isRemoveSessionLockEvent(event)) lock()
    }

    const isRemoveSessionLockEvent = ({ key, newValue }: StorageEvent): boolean => {
        return key === SA_LOCKED_STORAGE_KEY && newValue !== ID
    }

    const init = async () => {
        return new Promise<void>((resolve) => {
            setTimeout(
                () => {
                    lockSession()
                    resolve()
                },
                Math.floor(Math.random() * MAX_INIT_TIME_DELAY_MS),
            )
        })
    }

    return {
        lockSession,
        releaseSession,
        isLockedOut,
        init,
    }
}

const SessionManager = (function (): ISessionManager {
    let instance: ISessionManagerInstance

    const create = () => {
        if (!!instance) return instance

        const ID = crypto.randomUUID()
        const { lockSession, releaseSession, isLockedOut, init } = createSessionManager(ID)

        releaseSession()

        instance = {
            lockSession,
            isLockedOut,
            init,
            ID,
        }

        return instance
    }

    const getSessionManager = () => {
        if (!instance) create()

        return instance
    }

    return {
        getSessionManager,
        create,
    }
})()

export default SessionManager
