import { jsx, Fragment, jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { Modifier, EditorState, getDefaultKeyBinding } from "draft-js";
import { EditorState as EditorState2 } from "draft-js";
import { useEffect, useMemo } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-player";
import { ClipLoader } from "react-spinners";
import Button from "./Button.mjs";
import "react-accessible-headings";
import "./index-CQvitumL.mjs";
import "./Form.mjs";
import "./Input.mjs";
import "./Popup.mjs";
import "./ReactDatePicker.mjs";
import "./Tooltip.mjs";
import "./Tag.mjs";
import "./TriggeredPopup.mjs";
import "./emoji-picker-l0sNRNKZ.mjs";
import { I as Images, E as EmojiPicker, o as onSelectEmoji } from "./misc-CUSLsXKT.mjs";
import { e, s } from "./misc-CUSLsXKT.mjs";
const documentIcon = "data:image/svg+xml,%3csvg%20width='28'%20height='36'%20viewBox='0%200%2028%2036'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M2.75%2036C1.98333%2036%201.33333%2035.7333%200.8%2035.2C0.266667%2034.6667%200%2034.0167%200%2033.25V2.75C0%201.98333%200.266667%201.33333%200.8%200.8C1.33333%200.266667%201.98333%200%202.75%200H19.45L28%208.55V33.25C28%2034.0167%2027.7333%2034.6667%2027.2%2035.2C26.6667%2035.7333%2026.0167%2036%2025.25%2036H2.75ZM2.75%2034.45H25.25C25.55%2034.45%2025.825%2034.325%2026.075%2034.075C26.325%2033.825%2026.45%2033.55%2026.45%2033.25V9.2H18.7V1.55H2.75C2.45%201.55%202.175%201.675%201.925%201.925C1.675%202.175%201.55%202.45%201.55%202.75V33.25C1.55%2033.55%201.675%2033.825%201.925%2034.075C2.175%2034.325%202.45%2034.45%202.75%2034.45ZM1.55%2033.25V1.55C1.55%201.55%201.55%201.675%201.55%201.925C1.55%202.175%201.55%202.45%201.55%202.75V33.25Z'%20fill='%23353535'/%3e%3c/svg%3e";
const cancelIcon = "data:image/svg+xml,%3csvg%20width='20'%20height='20'%20viewBox='0%200%2020%2020'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M6.4%2015L10%2011.4L13.6%2015L15%2013.6L11.4%2010L15%206.4L13.6%205L10%208.6L6.4%205L5%206.4L8.6%2010L5%2013.6L6.4%2015ZM10%2020C8.61667%2020%207.31667%2019.7373%206.1%2019.212C4.88333%2018.6873%203.825%2017.975%202.925%2017.075C2.025%2016.175%201.31267%2015.1167%200.788%2013.9C0.262667%2012.6833%200%2011.3833%200%2010C0%208.61667%200.262667%207.31667%200.788%206.1C1.31267%204.88333%202.025%203.825%202.925%202.925C3.825%202.025%204.88333%201.31233%206.1%200.787C7.31667%200.262333%208.61667%200%2010%200C11.3833%200%2012.6833%200.262333%2013.9%200.787C15.1167%201.31233%2016.175%202.025%2017.075%202.925C17.975%203.825%2018.6873%204.88333%2019.212%206.1C19.7373%207.31667%2020%208.61667%2020%2010C20%2011.3833%2019.7373%2012.6833%2019.212%2013.9C18.6873%2015.1167%2017.975%2016.175%2017.075%2017.075C16.175%2017.975%2015.1167%2018.6873%2013.9%2019.212C12.6833%2019.7373%2011.3833%2020%2010%2020Z'%20fill='%231D65A1'/%3e%3c/svg%3e";
const EditorImage = ({ decoratedText, onDeleteFile, editorState }) => {
  const files2 = decoratedText.match(new RegExp("(?<=\\[).+?(?=\\])", "g")).map((m) => {
    const [name, type, loadingState, objectUrl] = m.split("~");
    return {
      name,
      type,
      loadingState,
      objectUrl: objectUrl === "null" ? null : objectUrl
    };
  });
  const onKeyDownDeleteButton = (evt, i, fileName, editorState2) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (evt.keyCode === 32) {
      onDeleteFile(i, fileName, editorState2);
    }
  };
  return /* @__PURE__ */ jsx(Fragment, { children: files2.map((f, i) => /* @__PURE__ */ jsxs("div", { className: "sa-wysiwyg-image", children: [
    /* @__PURE__ */ jsx("img", { src: f.objectUrl ?? documentIcon, alt: "" }),
    /* @__PURE__ */ jsxs("div", { className: "sa-wysiwyg-image-meta", children: [
      /* @__PURE__ */ jsx("p", { children: f.name }),
      /* @__PURE__ */ jsx("p", { children: f.type })
    ] }),
    /* @__PURE__ */ jsx("div", { className: "sa-wysiwyg-image-button", children: f.loadingState === "loading" ? /* @__PURE__ */ jsx(ClipLoader, { size: 16 }) : f.loadingState === "waiting" ? /* @__PURE__ */ jsx(
      Button,
      {
        onClick: () => {
          onDeleteFile(i, f.name, editorState);
        },
        onKeyDown: (evt) => {
          onKeyDownDeleteButton(evt, i, f.name, editorState);
        },
        children: /* @__PURE__ */ jsx("img", { src: cancelIcon, alt: "" })
      }
    ) : /* @__PURE__ */ jsx(Fragment, {}) })
  ] })) });
};
const CHAT_ATTACHMENT_REGEX = /@SA_IMAGE\[([[\w.\-_#@!£$%|^"':;+={}?()[\] ]+~[\w\\/]+~\w+(.*)+\])+/gm;
function strategy(contentBlock, callback) {
  findWithRegex(CHAT_ATTACHMENT_REGEX, contentBlock, callback);
}
function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}
function createDecorator(saProps) {
  const component = (editorProps) => {
    return /* @__PURE__ */ jsx(EditorImage, { ...saProps, ...editorProps });
  };
  return {
    strategy,
    component
  };
}
const WarningBackgroundIcon = "data:image/svg+xml,%3csvg%20width='40'%20height='40'%20viewBox='0%200%2040%2040'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3crect%20width='40'%20height='40'%20fill='%23E41D00'/%3e%3c/svg%3e";
const WarningIcon = "data:image/svg+xml,%3csvg%20width='20'%20height='20'%20viewBox='0%200%2020%2020'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20fill-rule='evenodd'%20clip-rule='evenodd'%20d='M10.7757%201.33241C10.4408%200.667842%209.55915%200.667842%209.22423%201.33241L0.109144%2018.5863C-0.212037%2019.2236%200.214153%2020.0007%200.884885%2020.0007H19.1151C19.7858%2020.0007%2020.212%2019.2236%2019.8909%2018.5863L10.7757%201.33241ZM9.64491%2014.6666C9.5348%2014.6666%209.44396%2014.634%209.37239%2014.5689C9.30082%2014.5038%209.26503%2014.4211%209.26503%2014.3209V6.95662C9.26503%206.84641%209.30082%206.76124%209.37239%206.70113C9.44396%206.64101%209.5348%206.61095%209.64491%206.61095H10.3551C10.4652%206.61095%2010.5561%206.64351%2010.6276%206.70864C10.6992%206.77377%2010.735%206.85643%2010.735%206.95662V14.3209C10.735%2014.4211%2010.6992%2014.5038%2010.6276%2014.5689C10.5561%2014.634%2010.4652%2014.6666%2010.3551%2014.6666H9.64491ZM9.22374%2017.0337C9.29531%2017.0988%209.38615%2017.1314%209.49626%2017.1314H10.4873C10.5974%2017.1314%2010.691%2017.0988%2010.768%2017.0337C10.8451%2016.9686%2010.8837%2016.8859%2010.8837%2016.7857V15.8689C10.8837%2015.7687%2010.8451%2015.6861%2010.768%2015.621C10.691%2015.5558%2010.5974%2015.5233%2010.4873%2015.5233H9.49626C9.38615%2015.5233%209.29531%2015.5558%209.22374%2015.621C9.15217%2015.6861%209.11638%2015.7687%209.11638%2015.8689V16.7857C9.11638%2016.8859%209.15217%2016.9686%209.22374%2017.0337Z'%20fill='white'/%3e%3c/svg%3e";
const CloseIcon = "data:image/svg+xml,%3csvg%20width='8'%20height='9'%20viewBox='0%200%208%209'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M6.86193%200.695262C7.12228%200.434913%207.54439%200.434913%207.80474%200.695262C8.06509%200.955612%208.06509%201.37772%207.80474%201.63807L4.943%204.5L7.80474%207.36193C8.03905%207.59624%208.06248%207.96158%207.87503%208.2221L7.80474%208.30474C7.54439%208.56509%207.12228%208.56509%206.86193%208.30474L4%205.443L1.13807%208.30474C0.903757%208.53905%200.538416%208.56248%200.277896%208.37503L0.195262%208.30474C-0.0650874%208.04439%20-0.0650874%207.62228%200.195262%207.36193L3.057%204.5L0.195262%201.63807C-0.0390524%201.40376%20-0.0624839%201.03842%200.124968%200.777896L0.195262%200.695262C0.455612%200.434913%200.877722%200.434913%201.13807%200.695262L4%203.557L6.86193%200.695262Z'%20fill='%23696969'/%3e%3c/svg%3e";
const WarningMessage = ({ setIsShowWarningMessage }) => {
  return /* @__PURE__ */ jsxs("div", { className: "sa-warning-notification-wrapper", children: [
    /* @__PURE__ */ jsxs("div", { className: "sa-warning-notification-icon-wrapper", children: [
      /* @__PURE__ */ jsx("img", { src: WarningBackgroundIcon, alt: "warning-background-icon", className: "sa-warning-background-icon" }),
      /* @__PURE__ */ jsx("img", { src: WarningIcon, alt: "warning-icon", className: "sa-warning-icon" })
    ] }),
    /* @__PURE__ */ jsx("p", { className: "sa-warning-not-config-text", children: "*Automated value can’t be populated, review it." }),
    /* @__PURE__ */ jsx("div", { className: "sa-warning-notification-icon-wrapper", children: /* @__PURE__ */ jsx("img", { src: CloseIcon, alt: "close-icon", className: "sa-warning-close-icon", onClick: () => setIsShowWarningMessage(false) }) })
  ] });
};
const iconClassName = "editor-toolbar-icon";
const defaultEditorOptions = {
  options: ["inline", "list", "link"],
  inline: {
    options: ["bold", "italic", "underline"],
    bold: { icon: Images.Bold, className: iconClassName },
    italic: { icon: Images.Italic, className: iconClassName },
    underline: { icon: Images.Underline, className: iconClassName }
  },
  list: {
    options: ["unordered", "ordered"],
    unordered: { icon: Images.Unordered },
    ordered: { icon: Images.Ordered }
  },
  link: {
    className: iconClassName,
    defaultTargetOption: "_blank",
    link: { icon: Images.Hyperlink },
    options: ["link"]
  },
  emoji: {}
};
const WYSIWYG = ({
  editorState,
  onEditorStateChange,
  label,
  required,
  options,
  toolbarButtons,
  placeholder,
  enableQuickReplies,
  handleKeyCommand,
  quickRepliesComponent,
  files,
  onDeleteFile,
  isShowWarningMessage,
  setIsShowWarningMessage,
  keyBindingFn,
  handleReturn,
  wrapperClassName,
  editorClassName,
  toolbarClassName,
  wysiwygClassName,
  defaultOptionsToUse
}) => {
  document.querySelectorAll(".public-DraftEditor-content").forEach((item) => {
    item.setAttribute("data-noShortcut", "true");
  });
  if (enableQuickReplies && quickRepliesComponent) {
    toolbarButtons == null ? void 0 : toolbarButtons.push(quickRepliesComponent);
  }
  const defaultKeyBindingFn = (e2) => {
    if (e2.keyCode === 13 && e2.shiftKey) {
      return "sa-editor-send";
    }
    return getDefaultKeyBinding(e2);
  };
  useEffect(() => {
    setTimeout(() => {
      var _a, _b, _c;
      (_a = document.getElementsByClassName("sa-wysiwyg-toolbar")) == null ? void 0 : _a[0].setAttribute("tabindex", "0");
      (_c = (_b = document.getElementsByClassName("sa-wysiwyg-send")) == null ? void 0 : _b[0]) == null ? void 0 : _c.setAttribute("tabindex", "0");
      const buttons = document.getElementsByClassName("rdw-option-wrapper");
      for (let i = 0; i < buttons.length; i++) {
        buttons[i].setAttribute("tabindex", "0");
      }
      const buttons2 = document.getElementsByClassName("rdw-inline-wrapper");
      for (let i = 0; i < buttons2.length; i++) {
        buttons2[i].setAttribute("tabindex", "0");
      }
    }, 150);
  }, []);
  useEffect(() => {
    const stringToInsert = (files == null ? void 0 : files.length) ? "@SA_IMAGE" + files.map((f) => {
      const type = f.file.type.replace("vnd.openxmlformats-officedocument.wordprocessingml.document", "doc").replace("msword", "doc").replace("vnd.ms-powerpoint", "ppt").replace("vnd.openxmlformats-officedocument.presentationml.presentation", "ppt").replace("vnd.ms-excel", "xls").replace("vnd.openxmlformats-officedocument.spreadsheetml.sheet", "xls");
      return `[${f.file.name}~${type}~${f.loadingState}~${f.file.type.split("/")[0] === "image" ? getObjectUrl(f.file) : "null"}]`;
    }).join("") : "";
    if (stringToInsert) {
      const newEditorState = insertText(`
${stringToInsert}`, editorState);
      onEditorStateChange(newEditorState);
      setTimeout(() => {
        var _a;
        (_a = document.getElementsByClassName("DraftEditor-root")[0]) == null ? void 0 : _a.click();
      }, 100);
    }
  }, [files]);
  const insertText = (text, editorState2) => {
    const currentContent = editorState2.getCurrentContent(), currentSelection = editorState2.getSelection();
    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );
    const newEditorState = EditorState.push(editorState2, newContent, "insert-characters");
    return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
  };
  const decorators = useMemo(() => {
    const decorators2 = [];
    if (files && onDeleteFile) {
      decorators2.push(createDecorator({
        onDeleteFile,
        editorState
      }));
    }
    return decorators2;
  }, [files, onDeleteFile]);
  function getDefaultOptions(defaultOptionsToUse2) {
    if (!defaultOptionsToUse2 || !defaultOptionsToUse2.length)
      return defaultEditorOptions;
    const requiredOptions = defaultOptionsToUse2.reduce(
      (opts, key) => ({ ...opts, [key]: defaultEditorOptions[key] }),
      {}
    );
    requiredOptions.options = defaultOptionsToUse2.filter((x) => x != "emoji");
    return requiredOptions;
  }
  return /* @__PURE__ */ jsxs(
    "div",
    {
      className: classNames("sa-wysiwyg", wysiwygClassName),
      onDragOver: (e2) => {
        console.log("drag-over");
      },
      children: [
        isShowWarningMessage && setIsShowWarningMessage && /* @__PURE__ */ jsx(WarningMessage, { setIsShowWarningMessage }),
        label && /* @__PURE__ */ jsx("div", { className: "sa-wysiwyg-label", children: /* @__PURE__ */ jsxs("label", { children: [
          label,
          /* @__PURE__ */ jsx("span", { "aria-hidden": "true", children: required ? "*" : "" })
        ] }) }),
        /* @__PURE__ */ jsx(
          Editor,
          {
            spellCheck: true,
            editorState,
            onEditorStateChange,
            wrapperClassName: classNames(
              "sa-wysiwyg-wrapper",
              wrapperClassName
            ),
            editorClassName: classNames(
              "sa-wysiwyg-editor",
              editorClassName
            ),
            toolbarClassName: classNames(
              "sa-wysiwyg-toolbar",
              toolbarClassName
            ),
            customDecorators: decorators,
            toolbarCustomButtons: [getDefaultOptions(defaultOptionsToUse).emoji ? /* @__PURE__ */ jsx(EmojiPicker, { above: true, onSelectEmoji: (emoji) => onSelectEmoji(emoji, editorState, onEditorStateChange) }, "emoji picker") : /* @__PURE__ */ jsx(Fragment, {})].concat(toolbarButtons ?? []),
            placeholder,
            toolbar: { ...getDefaultOptions(defaultOptionsToUse), ...options },
            handleKeyCommand,
            keyBindingFn: keyBindingFn ?? defaultKeyBindingFn,
            handleReturn
          }
        )
      ]
    }
  );
};
const getObjectUrl = (file) => window.URL.createObjectURL(file);
export {
  WYSIWYG,
  EditorState2 as WYSIWYGEditorState,
  defaultEditorOptions,
  e as emojis,
  s as stateToHTMLOptions
};
