import { Box } from '@missionlabs/smartagent-app-components'
import { H } from 'react-accessible-headings'
import Search from 'images/search.svg'
import React, { useEffect, useState } from 'react'
import { BeatLoader } from 'react-spinners'
import { fetchChatTranscript } from 'services/api/api.contact'
import { ISocialChatMessage, SourceMessage, SourceMessageMetada } from 'store/chat/chat.state'
import SocialChatMessages from '../ChatMessenger/SocialChatMessages'
import './chat-transcripts.scss'

interface Props {
    contactID?: string
    socialChatTranscript?: ISocialChatMessage[]
}

const transformChatMessageContent = (content: string | undefined) => {
    if (!content) return
    try {
        const parsed = JSON.parse(content.replace('\n', ''))

        const subParsed = Object.values<{ type?: string }>(parsed)[0]
        if (!!subParsed.type) return subParsed

        if (parsed.type) return parsed
        // eslint-disable-next-line no-empty
    } catch (e) {
    }
    return content
}

const mapSocialAttributes = (attributes: any): {sourceMessage: SourceMessage,
    sourceMessageMetada: SourceMessageMetada} | undefined => {
    try{
        return {
            sourceMessage: {
                content: attributes['sa-source-message-content'],
                link: attributes['sa-source-message-link'],
                timestamp:
                    Number(attributes['sa-source-message-timestamp']) || 0,
            },
            sourceMessageMetada: {
                content: attributes['sa-source-message-metadata-content'],
                name: attributes['sa-source-message-metadata-name'],
                timestamp:
                    Number(attributes['sa-source-message-metadata-timestamp']) || 0,
            }
        };
    } catch (e) {
        return undefined;
    }
}

export const ChatTranscripts: React.FC<Props> = ({ contactID, socialChatTranscript }) => {
    const [transcript, setTranscript] = useState<connect.ChatTranscriptItem[] | undefined>(
        undefined,
    )
    const [socialAttributes, setSocialAttributes] = useState<{sourceMessage: SourceMessage
        sourceMessageMetada: SourceMessageMetada} | undefined>(undefined)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getTranscript = async () => {
            if (!contactID) return
            setLoading(true)
            const transcript = await fetchChatTranscript(contactID)
            setTranscript(transcript.data?.Transcript);
            setSocialAttributes(mapSocialAttributes(transcript.data?.Attributes));
            setLoading(false)
        }
        getTranscript()
    }, [])

    const renderConnectTranscripts = () => {
        const transcriptsFilteredAndSorted =
            transcript
                ?.filter((t) => !!t?.Content || t.Attachments?.length)
                .sort(
                    (a, b) =>
                        new Date(a.AbsoluteTime).getTime() - new Date(b.AbsoluteTime).getTime(),
                ) || []

        const transcriptsAsChatMessages: ISocialChatMessage[] = transcriptsFilteredAndSorted.map(
            (transcript) =>
                ({
                    ...transcript,
                    content: transformChatMessageContent(transcript.Content),
                    contactID,
                }) as ISocialChatMessage,
        )

        if (transcriptsAsChatMessages.length === 0) {
            return (
                <div className="row column center middle md-pad">
                    <img src={Search} alt="Search" width="24px" height="24px" />
                    <p>No transcripts have been found</p>
                </div>
            )
        } else {
            return (
                <SocialChatMessages
                    chatMessagesProp={transcriptsAsChatMessages}
                    socialAttributes={socialAttributes}
                    className="sa-chat-transcript-messages"
                />
            )
        }
    }

    return (
        <Box
            alt
            collapse
            boxLabel="Messages"
            className="chat-transcripts-wrapper"
            header={<H>Chat Transcript</H>}
        >
            {socialChatTranscript?.length ? (
                <SocialChatMessages
                    chatMessagesProp={socialChatTranscript}
                    className="sa-chat-transcript-messages"
                />
            ) : loading ? (
                <div className="row column center middle md-pad">
                    <BeatLoader />
                </div>
            ) : (
                renderConnectTranscripts()
            )}
        </Box>
    )
}
