import classnames from 'classnames'
import useContact from 'hooks/redux/useContact'
import useHasFeature, { AppFeatures as AF, useGetFeatureConfig } from 'hooks/useHasFeature'
import React from 'react'
import { Widget } from 'store/app/app.state'
import HistoricMetrics from 'views/Metrics/HistoricMetrics'
import ContactHistory, {
    Props as ContactHistoryProps,
} from 'views/Metrics/HistoricMetrics/ContactHistory'
import { CallComprehension } from 'widgets/CallComprehension'
import { CallRecording } from 'widgets/CallRecording'
import CallTranscription from 'widgets/CallTranscription'
import { ContactDetails } from 'widgets/ContactDetails'
import { ContactEvaluation } from 'widgets/ContactEvaluation'
import { withContactProviderContext } from 'widgets/contexts/contact'
import Stats from '../Stats'
import './widgets.scss'

export enum WidgetType {
    QA_EVALUATION_STATUS = 'QA_EVALUATION_STATUS',
}

interface Props {
    type?: Widget['type']
    widgetProps?: any
    showNone?: boolean
    selectedView?: string
    render?(
        getWidget: (name: AF | WidgetType, props?: any) => React.ReactNode | null,
    ): React.ReactNode
    exclude?: string[]
    isOnContact?: boolean
}

export const WidgetTypes = {
    contactHistory: [AF.CONTACT_HISTORY],
    callContact: [
        AF.CALL_DETAILS,
        AF.CALL_RECORDING,
        AF.CALL_TRANSCRIPTION,
        AF.CALL_COMPREHENSION,
        WidgetType.QA_EVALUATION_STATUS,
        AF.CONTACT_HISTORY,
    ],
    chatContact: [AF.CHAT_DETAILS, AF.CONTACT_HISTORY, AF.HISTORIC_METRICS],
    realTime: [AF.CALL_PLUCKING],
    taskContact: [AF.TASK_DETAILS, AF.CONTACT_HISTORY],
}

//@ts-ignore
const _Widgets: React.FC<Props> = ({
    type,
    selectedView,
    showNone,
    render,
    widgetProps,
    exclude,
    isOnContact,
}) => {
    const chProps: ContactHistoryProps = {
        isWidget: true,
        type: 'contactHistory',
        showNone,
        selectedView,
    }
    const isHidden = () => {
        if (showNone) return true
        if (selectedView && type !== selectedView) return true
        return false
    }

    const hasFeature = useHasFeature()
    const getFeatureConfig = useGetFeatureConfig()
    const contact = useContact()

    const renderWidget = (name: string, props: any = widgetProps) => {
        if (exclude?.includes(name)) return null

        // only thing we want to display during a contact is Contact History
        if (isOnContact) {
            if (name === AF.CONTACT_HISTORY) {
                return hasFeature(AF.CONTACT_HISTORY) && <ContactHistory {...chProps} />
            }
            if (name === AF.CALL_PLUCKING) {
                return hasFeature(AF.CALL_PLUCKING) && <Stats />
            }
            return null
        }

        // only thing we want to display on the Contact History tab is Contact History
        if (selectedView === 'contactHistory') {
            if (name === AF.CONTACT_HISTORY) {
                return hasFeature(AF.CONTACT_HISTORY) && <ContactHistory {...chProps} />
            }
            return null
        }

        //TODO: make these dynamic imports
        switch (name) {
            case AF.CALL_DETAILS:
                return (
                    hasFeature(AF.CALL_DETAILS) && (
                        <ContactDetails
                            {...(getFeatureConfig(AF.CALL_DETAILS) as any)}
                            {...props}
                        />
                    )
                )
            case AF.CHAT_DETAILS:
                return hasFeature(AF.CHAT_DETAILS) && <ContactDetails {...props} />
            case AF.CALL_RECORDING:
                return (
                    hasFeature(AF.CALL_RECORDING) && (
                        <CallRecording
                            key={contact?.ID}
                            {...(getFeatureConfig(AF.CALL_RECORDING) as any)}
                            {...props}
                        />
                    )
                )
            case AF.CALL_TRANSCRIPTION:
                return (
                    hasFeature(AF.CALL_TRANSCRIPTION) && (
                        <CallTranscription
                            {...(getFeatureConfig(AF.CALL_TRANSCRIPTION) as any)}
                            {...props}
                        />
                    )
                )
            case AF.CALL_COMPREHENSION:
                return (
                    hasFeature(AF.CALL_COMPREHENSION) && (
                        <CallComprehension
                            {...(getFeatureConfig(AF.CALL_COMPREHENSION) as any)}
                            {...props}
                        />
                    )
                )
            case AF.CALL_PLUCKING:
                return hasFeature(AF.CALL_PLUCKING) && <Stats />
            case WidgetType.QA_EVALUATION_STATUS:
                return hasFeature(AF.QA) && <ContactEvaluation {...props} />
            case AF.HISTORIC_METRICS:
                return (
                    hasFeature(AF.HISTORIC_METRICS) &&
                    contact?.attributes['sa-social-media-platform'] && <HistoricMetrics isWidget />
                )
            case AF.CONTACT_HISTORY:
                return hasFeature(AF.CONTACT_HISTORY) && <ContactHistory {...chProps} />
            default:
                return null
        }
    }

    const getWidget = (name: AF | WidgetType, props?: any) => renderWidget(name, props)

    const filteredWidgets = type ? WidgetTypes[type] : []

    return render ? (
        render(getWidget)
    ) : (
        <>
            {filteredWidgets.map((w) => (
                <div
                    cy-ref={w}
                    key={w}
                    className={classnames({
                        hidden: isHidden(),
                    })}
                >
                    {getWidget(w)}
                </div>
            ))}
        </>
    )
}

export const Widgets = withContactProviderContext<Props>(_Widgets)
