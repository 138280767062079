import { Dot, TimeElapsed } from '@missionlabs/smartagent-app-components'
import classNames from 'classnames'
import useAppConfig from 'hooks/redux/useAppConfig'
import useSettings from 'hooks/redux/useSettings'
import useUser from 'hooks/redux/useUser'
import useHasFeature, { AppFeatures, useGetFeatureConfig } from 'hooks/useHasFeature'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getSettings } from 'store/settings/settings.actions'
import { setState } from 'store/user/user.actions'
import { isNilOrEmpty } from 'utils'
import DownArrow from '../../images/arrow-down.svg'
import './status.scss'

interface Props {
    isAvailable: boolean
    statusName: string
    inStateSince?: Date | string | number
    disabled?: boolean
    isActiveUser?: boolean
}

const Status: React.FC<Props> = ({
    isAvailable,
    statusName,
    inStateSince,
    disabled,
    isActiveUser = false,
}) => {
    const { settings } = useSettings()

    const dispatch = useDispatch()
    const hasFeature = useHasFeature()
    const featureConfig = useGetFeatureConfig()

    const { states = [], showNextStatus, afterCallStatus } = useUser() ?? {}
    const [isAwol, setAwol] = useState(false)

    // Handle the agent-status feature
    const agentStatusFeature = hasFeature(AppFeatures.AGENT_STATUS)
    const awolStatusName = useAppConfig()?.awolStatusName || 'Offline'

    const awolState =
        states.find((state) => state.name === awolStatusName) ||
        states.find((state) => state.name === 'Offline')

    useEffect(() => {
        if (agentStatusFeature && awolStatusName && isAwol && awolState) {
            dispatch(setState(awolState))
            setAwol(false)
        }
    }, [isAwol, awolState, dispatch, agentStatusFeature, awolStatusName])

    useEffect(() => {
        if (isNilOrEmpty(settings)) dispatch(getSettings())
    }, [settings, dispatch])

    const checkLimit = () => {
        if (!inStateSince) return null

        const date = typeof inStateSince === 'object' ? inStateSince : new Date(inStateSince)
        const durationMilliSecs = new Date(date).getTime()
        const secondsSinceChange = Math.round((new Date().getTime() - durationMilliSecs) / 1000)
        const status = statusName
        const limit =
            settings?.agentStatusTimes?.find(
                (element) => element.agentStatus?.toLowerCase() === status.toLowerCase(),
            )?.timeLimitSecs || 0
        return {
            duration: durationMilliSecs,
            awol: limit > 0 && secondsSinceChange > limit ? true : false,
        }
    }

    const renderTimer = () => {
        const limit = checkLimit()

        const duration = limit?.duration
        if (!duration) return null
        const awol = limit?.awol
        const date = new Date(duration)
        const seconds = (new Date().getTime() - duration) / 1000

        if (agentStatusFeature && awolStatusName && !isAwol && awol) setAwol(true)

        return (
            <span className={awol ? 'overTime' : 'underTime'}>
                <TimeElapsed
                    annotate
                    date={date}
                    hours={seconds > 3600}
                    days={seconds > 3600 * 24}
                />
            </span>
        )
    }
    return (
        <div
            className={classNames({
                'sa-status': true,
                disabled,
            })}
        >
            <Dot type={isAvailable ? 'positive' : 'negative'} />
            <span id="currentStatusLabel" className="sa-status-name">
                <span className="only-visible-to-screen-readers">
                    {statusName}, Current agent status:
                </span>
                {statusName}
            </span>

            <span aria-hidden="true">{!showNextStatus && renderTimer()}</span>
            {showNextStatus && isActiveUser && (
                <>
                    <div className="sa-status-verticalLine" />
                    <span className="sa-status-name">Next:</span>

                    <Dot type={afterCallStatus?.name === 'Available' ? 'positive' : 'negative'} />
                    <span className="sa-status-name">{afterCallStatus?.name}</span>
                </>
            )}

            {inStateSince && !disabled && <img src={DownArrow} width="20" height="20" alt="" />}
        </div>
    )
}

export default Status
