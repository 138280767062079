import { Loader, NoDataMessage } from 'components'
import useHasFeature, { AppFeatures, IConfigCheck, useGetFeatureConfig } from 'hooks/useHasFeature'
import useInterval from 'hooks/useInterval'
import throttle from 'lodash.throttle'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as MetricsActions from 'store/metrics/metrics.actions'
import { WebsocketMetricQueue } from 'store/metrics/metrics.state'
import { getRTMQueues } from 'store/settings/settings.actions'
import { getRTMQueuesFromState } from 'store/settings/settings.utils'
import RootState from 'store/state'
import { Queue } from 'store/user/user.state'
import { convertFeatureToWebSocketAction } from 'utils'
import { mergeRealtimeConfigColumns } from 'utils/mergeRealtimeConfigColumns'

import { Box, Table } from '@missionlabs/smartagent-app-components'
import { SATableColumn } from '@missionlabs/smartagent-app-components/dist/Table'

import allQueueColumns from './Columns/allQueueColumns'
import { getQueueColsFromConfig } from './Columns/utils'
import QueueMetricsHeader from './Header'
import { filteredQueueColumns, getLongPollingMetricData, getWebSocketMetricData } from './utils'

export interface Props {
    isRTMPage?: boolean
}

/*
This component handles both the long polling and websocket versions for all queue metrics.
This includes the queue stats page and the realtime-metrics page.
Unfortunately all of the functionality and the associated data types for the queue stats metrics, realtime-metrics page
metrics and websocket metrics are different, due to the time constraints in getting the websocket feature out,
we haven't had time to refactor all of this functionality as much as we would like to, which is why there is so much complexity
surrounding this component.
*/
const QueueMetrics: React.FC<Props> = ({ isRTMPage = false }) => {
    const dispatch = useDispatch()
    const getFeatureConfig = useGetFeatureConfig()
    let { showQueueSummary } = getFeatureConfig(AppFeatures.REALTIME_METRICS) || {}

    const [listenerID, setListenerID] = useState<string | undefined>()

    const websocket = useSelector((state: RootState) => state?.websocket)

    // Use the new hasFeature config option to check whether realtime metrics has the websocket enabled config
    const config: IConfigCheck = { websocketEnabled: true }
    const hasWebSocketFeature = useHasFeature(config)
    const isFeatureWebsocketEnabled = hasWebSocketFeature(AppFeatures.REALTIME_METRICS)
    const isWebsocketActive = !!(websocket?.config.webSocket.readyState === 1)
    const isWebsocketEnabledAndActive = isFeatureWebsocketEnabled && isWebsocketActive

    showQueueSummary = isRTMPage && !isWebsocketEnabledAndActive ? showQueueSummary : false

    const userTags = useSelector((state: RootState) => state.user?.tags)

    const hasFeature = useHasFeature()
    const isRTMRedesigned = hasFeature(AppFeatures.REALTIME_DATA_QUEUES_REDESIGNED)
    const realtimeQueueMetrics = useSelector(
        (state: RootState) => state.metrics?.realtimeQueueMetrics,
    )
    const statsQueueMetrics = useSelector((state: RootState) => state.metrics?.statsQueueMetrics)
    const websocketQueueMetrics = useSelector(
        (state: RootState) => state.metrics?.websocketQueueMetrics,
    )

    const filters = useSelector((state: RootState) => state.metrics?.filters)
    const { timeRange, queueIDs, timeZone } = filters

    const metricsConfig = useSelector((state: RootState) => state.metrics?.config)

    // queues is a list of all queues for realtime metrics, userQueues are the queues used to filter the stats page
    // queuesLoading is used by RTM page to determine whether there is queue data ready to show or not
    const [queues, queuesLoading] = useSelector(getRTMQueuesFromState)
    const userQueues = useSelector((state: RootState) => state.user?.queues)
    const statsQueueIds = userQueues?.map((q: Queue) => q.queueId.split('/queue/')[1])

    // metricsGroups refer to the metric groups objects that build up the columns for the WS enabled metrics
    const metricsGroups = useSelector((state: RootState) => state.metrics?.metricsGroups)
    // This is a horrible function that builds up the columns for the WS enabled table
    const wsColumns: (SATableColumn & { format?: string })[] =
        mergeRealtimeConfigColumns(metricsGroups)
    let cols: SATableColumn<any>[]

    if (isWebsocketEnabledAndActive) {
        cols = wsColumns
    } else if (isRTMPage) {
        cols = allQueueColumns

        if (metricsConfig) {
            cols = getQueueColsFromConfig(metricsConfig, true)
        }
    } else {
        cols = filteredQueueColumns

        if (metricsConfig) {
            cols = getQueueColsFromConfig(metricsConfig, false)
        }
    }

    const tableData = useMemo(() => {
        if (isWebsocketEnabledAndActive) {
            return getWebSocketMetricData(
                websocketQueueMetrics,
                queues,
                queueIDs,
                userQueues,
                isRTMPage,
                cols,
            )
        } else {
            const metricsToUse = isRTMPage ? realtimeQueueMetrics : statsQueueMetrics
            return getLongPollingMetricData({
                queues,
                queueIDs,
                statsQueueIds,
                queueMetrics: metricsToUse,
                showQueueSummary,
                isRTMPage,
                metricsConfig,
            })
        }
    }, [
        realtimeQueueMetrics,
        statsQueueMetrics,
        queues,
        websocketQueueMetrics,
        queueIDs,
        userQueues,
        cols,
    ])

    // This is designed to stop the constant updating of RTM for WS work
    // This should be removed and handled on the BE as it is unnecessarily expensive and pointless sending out more updates than the FE can handle
    const throttleDispatchMetrics = throttle(
        (value: WebsocketMetricQueue[]) => dispatch(MetricsActions.setWebsocketQueueMetrics(value)),
        2000,
        {
            leading: false,
            trailing: true,
        },
    )
    const throttleMetricsRequest = useCallback(
        (value: WebsocketMetricQueue[]) => throttleDispatchMetrics(value),
        [],
    )

    // Here if it is WS enabled, we add a listener for the realtime metrics data and update the store when messages come in
    useEffect(() => {
        if (!isWebsocketEnabledAndActive || listenerID) return

        const realtimeMetricsAction = convertFeatureToWebSocketAction(AppFeatures.REALTIME_METRICS)
        const websocketListenerID = websocket.addListener(realtimeMetricsAction, (data: any) => {
            const { queues, metricsGroups } = data.detail

            if (!websocketQueueMetrics?.length) {
                dispatch(MetricsActions.setWebsocketQueueMetrics(queues))
            } else {
                throttleMetricsRequest(queues)
            }

            dispatch(MetricsActions.setMetricsConfig(metricsGroups))
        })

        setListenerID(websocketListenerID)
        return () => {
            websocket.removeListener(listenerID!)
        }
    }, [websocket?.config.webSocket.readyState])

    useEffect(() => {
        if (!queues && userTags) dispatch(getRTMQueues(userTags))
        if (!isWebsocketEnabledAndActive) dispatch(MetricsActions.getQueueMetrics(isRTMPage))
    }, [dispatch, queues, timeRange, timeZone, queueIDs, userTags])

    useInterval(
        () => {
            if (userTags) dispatch(getRTMQueues(userTags))
        },
        60 * 60 * 1000,
    ) // refresh queues hourly

    // If not websocket enabled, set up long polling

    const queuesRefreshInterval = isRTMRedesigned ? 5000 : 60000
    useInterval(() => {
        if (!isWebsocketEnabledAndActive) dispatch(MetricsActions.getQueueMetrics(isRTMPage))
    }, queuesRefreshInterval)

    // New generic header component for all three instances
    const header = (
        <QueueMetricsHeader
            queues={queues}
            filters={filters}
            onFilterChange={(filters) => dispatch(MetricsActions.setMetricFilters(filters))}
            isRTMPage={isRTMPage}
            userQueues={userQueues}
        />
    )

    return (
        <Box
            className={isRTMPage ? 'realtime-metrics-panel' : ''}
            collapse
            header={header}
            boxLabel="Queues"
            alt
        >
            {(isRTMPage &&
                !isWebsocketEnabledAndActive &&
                !queuesLoading &&
                realtimeQueueMetrics) ||
            (!isRTMPage && !isWebsocketEnabledAndActive && statsQueueMetrics) ||
            (isWebsocketEnabledAndActive && websocketQueueMetrics) ? (
                <Table
                    sort="queueName"
                    selectable
                    cols={cols}
                    data={tableData && cols.length ? tableData : []}
                    caption="Real time queue metrics"
                    noData={
                        <NoDataMessage text={!cols.length ? 'No metrics configured' : undefined} />
                    }
                />
            ) : (
                <Loader />
            )}
        </Box>
    )
}

export default QueueMetrics
