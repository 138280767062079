import { ReactComponent as CrossIconFill } from 'images/icon-cross-small.svg'
import React from 'react'
import styles from './ContactCloseButton.module.scss'

interface Props {
    onKeyPress: (evt: React.KeyboardEvent) => void
    onClick?: (evt: React.MouseEvent) => void
}

const ContactCloseButton = ({ onKeyPress, onClick }: Props) => {
    return (
        <div
            tabIndex={0}
            role="button"
            onKeyPress={onKeyPress}
            className={styles.closeButton}
            onClick={onClick}
            data-test-id="contactCloseButton"
            aria-label="Close"
            aria-pressed="false"
        >
            <CrossIconFill />
        </div>
    )
}

export default ContactCloseButton
