import React, { useEffect } from 'react'
import { connect as _connect } from 'react-redux'
import { appError, initApp } from 'store/app/app.actions'
import { clearError, clearSoftphoneError, clearSuccess } from 'store/global/global.actions'
import RootState from 'store/state'
import { NotificationControl, getBodyWidth } from 'utils'

import { Button, Popup, Slideout } from '@missionlabs/smartagent-app-components'
import { Notification, PopupMessage } from 'components'
import { H, Level } from 'react-accessible-headings'
import Error from 'views/Error'
import Login from 'views/Login'
import Menu from 'views/Menu'

import ErrorBoundary from 'components/ErrorBoundary'
import { showAgents } from 'store/agents/agents.actions'
import Agents from 'views/Agents'
import Main from 'views/Main'
import ErrorPopup from '../../components/ErrorPopup'
import Logger from '../../logger'
import LoggedInWrapper from './LoggedInWrapper'
import { Redirect } from './Redirect'
import './main-base.scss'

interface Props extends ReturnType<typeof mapStateToProps> {}

interface Actions {
    initApp: () => void
    appError: typeof appError
    clearError: () => void
    clearSuccess: () => void
    showAgents: () => void
    clearSoftphoneError: () => void
}

const MainBase: React.FC<Props & Actions> = ({
    errorMessage,
    successMessage,
    showNotification,
    error,
    user,
    loading,
    authenticated,
    softphoneError,
    showIframe,
    agentsActive,
    logout,
    redirect,
    online,
    saIsAlreadyOpen,
    ...actions
}) => {
    useEffect(() => {
        NotificationControl.getPermission()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (saIsAlreadyOpen === false) {
            actions.initApp()
        }
    }, [saIsAlreadyOpen])

    const menuElem = document.querySelector('.menu')
    const bodyWidth = getBodyWidth()

    if (error) {
        const errorCode = Math.random().toString(36).substring(2, 10)
        Logger.error('APP-UNCAUGHT-OOPS-EXCEPTION', {
            error,
            errorCode,
        })
        return <Error errorCode={errorCode} />
    }

    return (
        <ErrorBoundary appError={actions.appError}>
            <div id="app" className={showIframe ? 'show-iframe' : ''}>
                {!!redirect && <Redirect to={redirect.to} state={redirect.state} />}

                {(softphoneError === connect.SoftphoneErrorTypes.SIGNALLING_CONNECTION_FAILURE ||
                    softphoneError ===
                        connect.SoftphoneErrorTypes.SIGNALLING_HANDSHAKE_FAILURE) && (
                    <Popup center noShadow={false}>
                        <Level value={2}>
                            <div className="top-modal">
                                <H>Call failed to connect</H>
                                <p>There was an issue with the network while connecting the call</p>
                                <p>
                                    Refreshing the page can sometimes fix this issue. If the problem
                                    persists, please contact support.
                                </p>
                                <Button styling="primary" onClick={actions.clearSoftphoneError}>
                                    OK
                                </Button>
                            </div>
                        </Level>
                    </Popup>
                )}

                <Slideout
                    slideDuration={300}
                    isMounted={agentsActive}
                    onOverlayClick={actions.showAgents}
                    width={bodyWidth < 520 ? bodyWidth - 70 : 450}
                    offset={menuElem?.clientWidth || 60}
                    from="left"
                    overlay
                >
                    <Agents />
                </Slideout>

                {loading || logout || saIsAlreadyOpen ? ( //If we have a specific logout then show auth screen otherwise depends if instance is selected or not
                    <Login />
                ) : authenticated && user ? (
                    <LoggedInWrapper>
                        <div className="content">
                            <Menu />
                            <Main />
                        </div>
                    </LoggedInWrapper>
                ) : (
                    <Login />
                )}

                {!!errorMessage && (
                    <PopupMessage clearMessage={actions.clearError} text={errorMessage} />
                )}
                {!!successMessage && (
                    <PopupMessage
                        clearMessage={actions.clearSuccess}
                        text={successMessage}
                        type="success"
                    />
                )}
                {!!showNotification && (
                    <Notification
                        type={showNotification.type}
                        header={showNotification.header}
                        text={showNotification.text}
                    />
                )}
                {!online && (
                    <ErrorPopup
                        text="You're not currently online. Please check your internet connection."
                        online={online}
                    />
                )}
            </div>
        </ErrorBoundary>
    )
}

function mapStateToProps(state: RootState) {
    return {
        logout: state.auth.logout || false,
        error: state.app.error,
        softphoneError: state.global.softphoneError,
        showIframe: state.global.showIframe,
        authenticated: state.auth.authenticated,
        loading: state.app.loading,
        user: !!state.user,
        errorMessage: state.global.error,
        successMessage: state.global.success,
        showNotification: state.global.showNotification,
        agentsActive: state.agents.agentsActive,
        redirect: state.global.redirect,
        online: state.global.online,
        saIsAlreadyOpen: state.global.saIsAlreadyOpen,
    }
}

export default _connect(mapStateToProps, {
    initApp,
    appError,
    clearError,
    clearSuccess,
    showAgents,
    clearSoftphoneError,
})(MainBase)
