import { ChatMessage, MessageUserType } from '@missionlabs/smartagent-chat-components-lib'
import ArrowDownRight from 'images/arrow-down-right.svg'
import React from 'react'
import './source-message-metadata.scss'

interface Props {
    messageContent?: string
    messageSenderName?: string
    timestamp: number
}

const SourceMessageMetadata: React.FC<Props> = ({
    messageContent,
    messageSenderName,
    timestamp,
}) => (
    <>
        {messageContent && (
            <>
                <div className="source-message-metadata-customer row start">
                    <img src={ArrowDownRight} alt="arrows" />
                    <p>Comment: {messageSenderName}</p>
                </div>
                <ChatMessage
                    className={'source-message-metadata-content'}
                    sendMessage={() => {}}
                    clientType={MessageUserType.AGENT}
                    senderType={MessageUserType.CUSTOMER}
                    time={new Date(timestamp ?? 0).toISOString()}
                    body={{
                        type: 'TEXT',
                        text: messageContent,
                    }}
                />
            </>
        )}
    </>
)

export default SourceMessageMetadata
