import { AnalyticsAttributes } from '@missionlabs/analytics-react'
import useApp from 'hooks/redux/useApp'
import useAuth from 'hooks/redux/useAuth'
import useUser from 'hooks/redux/useUser'
import { ReactNode, useMemo } from 'react'
import { getEnvironment } from 'utils'

interface Props {
    children: ReactNode
}

const Attributes = ({ children }: Props) => {
    const user = useUser()
    const app = useApp()
    const auth = useAuth()

    const attributesToSet = useMemo(
        () => ({
            environment: getEnvironment(),
            roles: auth?.roles,
            companyName: app?.ID,
            username: user?.username,
        }),
        [getEnvironment(), auth?.roles, app?.ID, user?.username],
    )

    return <AnalyticsAttributes attributes={attributesToSet}>{children}</AnalyticsAttributes>
}

export default Attributes
