import {
    ChatMessage,
    MessageUserType,
    TypingIndicator,
} from '@missionlabs/smartagent-chat-components-lib'
import {
    SAMessageType,
    SendMessageFunction,
} from '@missionlabs/smartagent-chat-components-lib/dist/ChatMessage/chat-message.types'
import { AppFeatures, useGetFeatureConfig } from 'hooks/useHasFeature'
import { forwardRef, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { IChatMessage } from 'store/chat/chat.state'
import MetaChatMessage from './ChatMessage/MetaChatMessage'
import './chat-message.scss'

interface Props {
    messages: IChatMessage[]
    isTyping?: boolean
    unread?: number
}

interface IRenderMessageOptions {
    sendMessage?: SendMessageFunction
    showDisplayName?: boolean
    showMessageReceipts?: boolean
    translatedFromOriginal?: boolean
}

const renderChatMessageConnect = (message: IChatMessage, options: IRenderMessageOptions) => {
    const { translatedFromOriginal = true } = options
    const sendMessage = options.sendMessage ?? (() => {})
    const displayName = message.DisplayName === 'SYSTEM_MESSAGE' ? 'System' : message.DisplayName
    switch (message.ContentType) {
        case 'text/plain':
        case 'text/markdown':
            return (
                <ChatMessage
                    sendMessage={sendMessage}
                    clientType={MessageUserType.AGENT}
                    senderType={message.ParticipantRole as MessageUserType}
                    time={message.AbsoluteTime}
                    senderDisplayName={options.showDisplayName ? displayName : undefined}
                    body={{
                        type: 'TEXT',
                        text: message.content as string,
                        translatedMessage:
                            typeof message?.OriginalMessage === 'string'
                                ? message.OriginalMessage
                                : undefined,
                        translatedFromOriginal: translatedFromOriginal,
                    }}
                    isMarkdownEnabled={true}
                    key={message.Id}
                    receiptsActive={options.showMessageReceipts}
                    deliveredTimestamp={message.deliveredTimestamp}
                    readTimestamp={message.readTimestamp}
                />
            )
        case 'application/vnd.amazonaws.connect.event.participant.joined':
        case 'application/vnd.amazonaws.connect.event.participant.left':
        case 'application/vnd.amazonaws.connect.event.chat.ended':
        case 'application/vnd.amazonaws.connect.event.transfer.failed':
        case 'application/vnd.amazonaws.connect.event.transfer.succeeded':
            return (
                <ChatMessage
                    sendMessage={sendMessage}
                    senderType={message.ParticipantId as MessageUserType}
                    clientType={MessageUserType.AGENT}
                    time={message.AbsoluteTime}
                    key={message.Id}
                    isMarkdownEnabled={true}
                >
                    <MetaChatMessage {...message} />
                </ChatMessage>
            )

        default:
            return message.ContentType
    }
}

const renderChatMessageSAMapper = (chat: IChatMessage, options: IRenderMessageOptions) => {
    const content = chat.content as SAMessageType
    const contentModifiable = { ...content } as SAMessageType
    const sendMessage = options.sendMessage ?? (() => {})

    if (
        content.type === 'TEXT_QUICKREPLIES' ||
        content.type === 'QUICK_REPLIES' ||
        content.type === 'QUICK_REPLIES_MULTILINE'
    ) {
        // Can't modify the content directly since it comes from a prop,
        // so we use a modifiable copy.
        //@ts-ignore: showQuickRepliesOnMount is part of the SAMessageType intersection
        contentModifiable.showQuickRepliesOnMount = false
    }

    return (
        <ChatMessage
            sendMessage={sendMessage}
            clientType={MessageUserType.AGENT}
            time={chat.AbsoluteTime}
            senderType={chat.ParticipantRole as MessageUserType}
            body={contentModifiable}
            isMarkdownEnabled={true}
            key={chat.Id}
            receiptsActive={options.showMessageReceipts}
            deliveredTimestamp={chat.deliveredTimestamp}
            readTimestamp={chat.readTimestamp}
        />
    )
}

export const renderMessage = (message: IChatMessage, options: IRenderMessageOptions) =>
    typeof message.content === 'string' || !message.content
        ? renderChatMessageConnect(message, options)
        : renderChatMessageSAMapper(message, options)

const ChatMessages = forwardRef<HTMLDivElement, Props>(
    ({ messages, unread = 0, isTyping = false }, ref) => {
        const showMessageReceipts =
            useGetFeatureConfig()(AppFeatures.CHAT)?.messageReceiptsActive === true

        const dispatch = useDispatch()

        const sendMessage = (message: string) => {
            dispatch(sendMessage(message))
        }

        const [old, nw] = useMemo(() => {
            if (!unread) return [messages, []]

            return [messages.slice(0, -unread), messages.slice(-unread)]
        }, [messages, unread])

        return (
            <div role="log" aria-live="assertive" ref={ref} className="sa-chat-messages">
                {old.map((msg) => renderMessage(msg, { sendMessage, showMessageReceipts }))}

                {!!nw.length && (
                    <>
                        {!!old.length && <div className="sa-new-messages-divider">NEW</div>}
                        {nw.map((msg) => renderMessage(msg, { sendMessage, showMessageReceipts }))}
                    </>
                )}

                {isTyping && (
                    <div className="sa-chat-message-wrapper">
                        <TypingIndicator senderType={MessageUserType.CUSTOMER} />
                    </div>
                )}
            </div>
        )
    },
)

export default ChatMessages
