import classnames from 'classnames'
import { ContactItem, ContactName } from 'components'
import ContactCloseButton from 'components/ContactItem/ContactCloseButton'
import React from 'react'
import { useDispatch } from 'react-redux'
import { removeTaskConnection, setSelectedTask } from 'store/tasks/tasks.actions'
import { ITask } from 'store/tasks/tasks.state'

import styles from './TaskListItem.module.scss'

interface TaskWithNewMessageCount extends ITask {
    newMessageCount?: number
}

interface Props {
    task: TaskWithNewMessageCount
    selected: boolean
    index: number
}

const TaskListItem: React.FC<Props> = ({ task, selected, index }) => {
    const dispatch = useDispatch()

    const isTaskMissed = () =>
        task.status === connect.ContactStateType.MISSED ||
        task.status === connect.ContactStateType.ERROR

    const onSelectTask = () => {
        dispatch(setSelectedTask(task.id))
    }

    const onCloseTask = (e: React.MouseEvent | React.KeyboardEvent) => {
        e.stopPropagation()
        dispatch(removeTaskConnection(task.id))
    }

    const handleKeyPress = (evt: React.KeyboardEvent) => {
        evt.key === 'Enter' && onCloseTask(evt)
    }

    const bodyClassName = classnames(styles.body, {
        [styles.missedContact]: isTaskMissed(),
        [styles.acw]: task.acw,
    })

    const headerClassName = classnames(styles.header, {
        [styles['header--missedContact']]: isTaskMissed(),
    })

    const getTaskName = () => {
        if (isTaskMissed()) {
            return 'Missed Task'
        }

        return task.attributes?.['sa-task-display-name']?.value || task.name
    }

    const taskDisplayDescription = task.attributes?.['sa-task-display-description']?.value ?? task.description

    return (
        <ContactItem
            onSelect={onSelectTask}
            acw={task.acw}
            selected={selected}
            index={index}
            disabled={task.status === connect.ContactStateType.CONNECTING}
            isMissed={isTaskMissed()}
            className={isTaskMissed() ? styles.contactButton : ''}
            bodyClassName={bodyClassName}
            CloseButton={() => (
                <div className={styles.closeButton}>
                    <ContactCloseButton onClick={onCloseTask} onKeyPress={handleKeyPress} />
                </div>
            )}
        >
            <div className={styles.wrapper}>
                <div className={styles.contactName}>
                    <ContactName className={headerClassName} name={getTaskName()} />
                </div>

                {!isTaskMissed() && (
                    <p title={taskDisplayDescription} className={`${styles.description}`}>
                        {taskDisplayDescription}
                    </p>
                )}
            </div>
            {!selected && task.newMessageCount && (
                <div className={`${styles.newMessageCount}`}>
                    <p>{task.newMessageCount}</p>
                </div>
            )}
        </ContactItem>
    )
}

export default TaskListItem
