import { login } from 'store/user/user.reducer'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import MetricsState, {
    AgentStatus,
    IAgentMetricData,
    IAgentUpdate,
    IFilters,
    IMetricsConfig,
    IMonitoringAgentData,
    IQueueMetricData,
    MetricsGroups,
    WebsocketMetricQueue,
} from './metrics.state'

const initialState: MetricsState = {
    filters: {
        timeRange: 'midnight',
        hierarchyStructure: undefined,
    },
    agentUpdates: [],
}

export const metricsSlice = createSlice({
    name: 'metrics',
    initialState,
    reducers: {
        getAgentMetrics(state, action: PayloadAction<IAgentMetricData[]>) {
            return {
                ...state,
                agentMetrics: action.payload,
                agentUpdates: [],
                agentsLoading: false,
            }
        },
        getAgentStatuses(
            state,
            action: PayloadAction<{
                agentStatuses: AgentStatus[]
                agentsLoading?: boolean
            }>,
        ) {
            const { agentsLoading, agentStatuses } = action.payload

            const parsedPayload: any = {
                agentStatuses,
            }

            if (agentsLoading !== undefined) {
                parsedPayload.agentsLoading = agentsLoading
            }

            return {
                ...state,
                ...parsedPayload,
            }
        },
        setStatsQueueMetrics(state, action: PayloadAction<IQueueMetricData[]>) {
            return { ...state, statsQueueMetrics: action.payload, queuesLoading: false }
        },
        setRealtimeQueueMetrics(state, action: PayloadAction<IQueueMetricData[]>) {
            return { ...state, realtimeQueueMetrics: action.payload, queuesLoading: false }
        },
        setWebsocketQueueMetrics(state, action: PayloadAction<WebsocketMetricQueue[]>) {
            return { ...state, websocketQueueMetrics: action.payload, queuesLoading: false }
        },
        setMetricsConfig(state, action: PayloadAction<MetricsGroups[]>) {
            return { ...state, metricsGroups: action.payload }
        },
        setMetricsConfigV2(state, action: PayloadAction<IMetricsConfig>) {
            return { ...state, config: action.payload }
        },
        loadingAgentMetrics(state) {
            return { ...state, agentsLoading: true }
        },
        loadingQueueMetrics(state) {
            return { ...state, queuesLoading: true }
        },
        setMetricFilters(state, action: PayloadAction<Partial<IFilters>>) {
            return { ...state, filters: { ...state.filters, ...action.payload } }
        },
        addAgentUpdate(state, action: PayloadAction<IAgentUpdate>) {
            return {
                ...state,
                agentUpdates: [...state.agentUpdates, action.payload],
            }
        },
        monitorAgent(state, action: PayloadAction<IMonitoringAgentData | undefined>) {
            if (!state) return state
            return { ...state, monitoringAgent: action.payload }
        },
        stopMonitorAgent(state) {
            const newState = {...state};
            delete newState.monitoringAgent;
            return newState;
        },
    },
    extraReducers: ({ addCase }) => {
        //This can be removed once old time ranges are flushed out from localstorage
        addCase(login, (state) => {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    timeRange:
                        typeof state.filters.timeRange === 'string'
                            ? 'midnight'
                            : state.filters.timeRange,
                },
            }
        })
    },
})

export const {
    getAgentMetrics,
    getAgentStatuses,
    setStatsQueueMetrics,
    setRealtimeQueueMetrics,
    setWebsocketQueueMetrics,
    setMetricsConfig,
    setMetricsConfigV2,
    loadingAgentMetrics,
    loadingQueueMetrics,
    setMetricFilters,
    addAgentUpdate,
    monitorAgent,
    stopMonitorAgent,
} = metricsSlice.actions

export type MetricsAction = ReturnType<
    (typeof metricsSlice.actions)[keyof typeof metricsSlice.actions]
>

export default metricsSlice.reducer
