import { ChatMessage, MessageUserType } from '@missionlabs/smartagent-chat-components-lib'
import React from 'react'
import './source-message.scss'
import { SocialMediaPlatform } from 'store/chat/chat.utils'

interface Props {
    messageContent?: string
    messageLink?: string
    timestamp: number
    socialMediaPlatform?: SocialMediaPlatform | undefined
}

const SourceMessage: React.FC<Props> = ({ messageContent, messageLink, timestamp, socialMediaPlatform }) => (
    <>
        <div className="source-message-title row middle between">
            <h2>{socialMediaPlatform ? socialMediaPlatform.toLowerCase().replace(/\b\w/g, char => char.toUpperCase()) : 'Original'} post:</h2>
            {messageLink && (
                <a href={messageLink} target="_blank" rel="noreferrer">
                    Link to original post
                </a>
            )}
        </div>
        {messageContent && (
            <>
                <ChatMessage
                    sendMessage={() => {}}
                    clientType={MessageUserType.AGENT}
                    senderType={MessageUserType.CUSTOMER}
                    time={new Date(timestamp ?? 0).toISOString()}
                    body={{
                        type: 'TEXT',
                        text: messageContent,
                    }}
                />
            </>
        )}
    </>
)

export default SourceMessage
