import type { Middleware } from '@reduxjs/toolkit'
import PhoneLib from 'google-libphonenumber'
import { isValidNumberForRegion } from 'libphonenumber-js'
import Logger from 'logger'
import { allocateNumber } from 'services/api/api.contact'
import { sipGateway } from 'services/api/api.sipGateway'
import { selectInstance } from 'store/app/app.reducer'
import { makeCall, mute, unmute } from 'store/call/call.reducer'
import { updateContactAttributes } from 'store/contact/contact.actions'
import { addError, setChangingState } from 'store/global/global.actions'
import RootState from 'store/state'
import * as UserReducer from 'store/user/user.reducer'
import { WebSocketCompanionActions } from 'store/websocketCompanion/websocketCompanion.state'
import { formatPhoneNumber, getNumberToDialThroughConnect, isAnyAction, matchPattern } from 'utils'

import { recoverContacts } from './ccp.recoverContacts'
import listenToAgent from './listeners/listeners.agent'

let getAgent: () => connect.Agent

// Sets getAgent to the agent found in the mock connect namespace when running any tests
if (process.env.NODE_ENV === 'test') getAgent = () => connect.Agent.prototype

const ccpAgentMiddleware: Middleware<{}, RootState> = (store) => (next) => async (action) => {
    if (!isAnyAction(action)) return

    if (store.getState().app?.callProvider === 'kumodi') {
        return next(action)
    }

    const { websocketCompanion } = store.getState()

    switch (action.type) {
        case selectInstance.type:
            if (store.getState().global.saIsAlreadyOpen) return next(action)

            getAgent = listenToAgent(store)
            return next(action)
        case UserReducer.userAuthenticationHasSet.type:
            const agent = getAgent()
            recoverContacts(agent, store)
            return next(action)
        case makeCall.type: {
            const { number, endpoint, queueArn } = action.payload

            const success = () => next(action)

            const failure = (error: string) => {
                console.log('Error at MAKE_CALL', error)
                store.dispatch(
                    addError(
                        JSON.parse(error).message ||
                            'Oops, there was an error while trying to connect the call',
                    ),
                )
            }

            const getEndpoint = (number: string) => {
                const countryAbbreviation = store.getState().app.appConfig?.defaultCountryCode

                const { formattedNumber, countryCode } = getNumberToDialThroughConnect(
                    number,
                    countryAbbreviation,
                )

                console.log(`Connecting ${formattedNumber} in ${countryCode}`)

                return connect.Endpoint.byPhoneNumber(
                    formatPhoneNumber(
                        formattedNumber,
                        PhoneLib.PhoneNumberFormat.E164,
                        countryCode,
                    ),
                )
            }

            // Check if reallocation of outbound number is needed
            const {
                app: { ID, instance, appConfig },
                auth: { token },
            } = store.getState()

            // Check if Number hits numberMapping condition
            const numberMapping = appConfig!.outboundNumberMappings?.find((mapping) => {
                return mapping.number === number
            })

            // Check if number should hit SIP gateway condition
            const gatewayConfig = appConfig?.SIPgatewayConfig
            const isGatewayNumber = gatewayConfig?.conditions.some((v) => {
                return matchPattern(v.regexPattern, number)
            })
            const countryCodeExclusion = gatewayConfig?.exclusions?.countryCodes?.some(
                (countryCode) => {
                    return isValidNumberForRegion(number!, countryCode)
                },
            )
            const regexExclusion = gatewayConfig?.exclusions?.regexPatterns?.some(
                (regexPattern) => {
                    return matchPattern(regexPattern, number)
                },
            )
            console.log('CountryCodeExclusion', countryCodeExclusion)
            console.log('regexExclusion', regexExclusion)

            if (numberMapping) {
                store.dispatch(updateContactAttributes(ID, { 'sa-dialled-number': number }))
                getAgent()?.connect(getEndpoint(numberMapping.mapping), {
                    success,
                    failure,
                    queueARN: queueArn,
                })
            } else if (
                gatewayConfig &&
                isGatewayNumber &&
                (!countryCodeExclusion || number?.length === 7) &&
                !regexExclusion
            ) {
                console.log(`number ${number} hits sip gateway`)
                const data = await sipGateway(gatewayConfig, number!, token!)
                store.dispatch(updateContactAttributes(ID, { 'sa-dialled-number': number }))
                getAgent()?.connect(getEndpoint(data.gatewayE164), {
                    success,
                    failure,
                    queueARN: queueArn,
                })
            } else if (instance!.allocateOutbound && number) {
                allocateNumber(ID, instance!.ID, number, token!)
                    .then((response) => {
                        getAgent()?.connect(getEndpoint(response.numberE164), {
                            success,
                            failure,
                            queueARN: queueArn,
                        })
                    })
                    .catch(failure)
            } else {
                //Default make call logic
                console.log(`number ${number} hits default call logic`)
                const newEndpoint = number ? getEndpoint(number) : (endpoint as any)
                getAgent()?.connect(newEndpoint, { success, failure, queueARN: queueArn })
            }
            return
        }

        case UserReducer.userAvailable.type: {
            if (!getAgent()) return
            const routableState: connect.AgentStateDefinition | undefined = getAgent()
                .getAgentStates()
                .find((state) => state.type === connect.AgentStateType.ROUTABLE)

            if (!routableState || routableState.name === getAgent().getState().name) return

            getAgent().setState(routableState, {
                success: () => next(action),
                failure: (error: string) => {
                    console.error(new Error('Error at USER_AVAILABLE'))
                    store.dispatch(
                        addError(
                            JSON.parse(error).message ||
                                'Oops, there was an error while trying to set you to available',
                        ),
                    )
                    console.log(error)
                },
            })
            return
        }

        case UserReducer.userNotAvailable.type: {
            if (!getAgent()) return
            const notRoutableState: connect.AgentStateDefinition | undefined = getAgent()
                .getAgentStates()
                .find((state) => state.type === connect.AgentStateType.OFFLINE)
            if (!notRoutableState) return
            getAgent().setState(notRoutableState, {
                success: () => next(action),
                failure: (error: string) => {
                    console.error(new Error('Error at USER_NOT_AVAILABLE'))
                    store.dispatch(
                        addError(
                            JSON.parse(error).message ||
                                'Oops, there was an error while trying to set you to not available',
                        ),
                    )
                    console.log(error)
                },
            })
            return
        }

        case mute.type:
            getAgent()?.mute()

            websocketCompanion.client?.sendMessage(WebSocketCompanionActions.ON_CALL_MUTE_SUCCESS)

            return next(action)
        case unmute.type:
            getAgent()?.unmute()

            websocketCompanion.client?.sendMessage(WebSocketCompanionActions.ON_CALL_UNMUTE_SUCCESS)

            return next(action)

        case UserReducer.userSetState.type: {
            if (store.getState().global.changingState) {
                return
            }
            store.dispatch(setChangingState(action.payload.state.name))

            return getAgent()?.setState(
                action.payload.state,
                {
                    success: () => {
                        store.dispatch(setChangingState(''))
                        next(action)
                    },
                    failure: (error: string) => {
                        const errorMap: { [key: string]: string } = {
                            'Network Failure':
                                'Unable to update status. There is an issue with your internet connection, refreshing the tab may fix this issue',
                        }

                        Logger.error('SET AGENT STATE ERROR', new Error(error))

                        const errorMsg = errorMap[JSON.parse(error).message]
                        if (errorMsg) {
                            store.dispatch(addError(errorMsg))
                        } else {
                            store.dispatch(addError('Unable to update status'))
                        }

                        store.dispatch(setChangingState(''))
                    },
                },
                { enqueueNextState: true },
            )
        }

        case UserReducer.getQuickConnects.type: {
            getAgent()?.getEndpoints(getAgent().getAllQueueARNs(), {
                success: (response: any) => {
                    action.payload = response.addresses
                    return next(action)
                },
                failure: (error: string) => {
                    console.error(new Error('Error at GET_QUICK_CONNECTS'))
                    store.dispatch(
                        addError(
                            JSON.parse(error).message ||
                                'Oops, there was an error while trying to retrieve your Quick Connects',
                        ),
                    )
                },
            })
            return
        }

        default:
            return next(action)
    }
}

export default ccpAgentMiddleware
